.home-layout-container {
  padding: 20px;
  background-color: #FFFFFF;
}

@media only screen and (min-width: 768px) {
  .home-service-info-responsive-container {
    /* display: flex;
    position: absolute;
    top: 300px;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    display: flex;
    justify-content: space-between;
    max-width: 850px; */
    display: none;
  }
}

/* @media only screen and (min-width: 1024px) {
  .home-service-info-responsive-container {
    top: 480px;
  }
} */