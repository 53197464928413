.home-service-carousel-card-container {
  min-width: 266px;
  max-width: 266px;
  height: 386px;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0px 4px 30px 0px #0000001A;
  margin: 0 15px;
  position: relative;
}

.home-service-carousel>div:last-child>div {
  margin: 0 30px 0 15px;
}

.home-service-carousel-card-container .ant-image {
  width: 100%;
}

.home-service-carousel-card-image {
  width: 100%;
  height: 157px;
  object-fit: cover;
}

.home-service-carousel-card-badge {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: absolute;
  top: 15px;
  left: 15px;
  background-color: #FFFFFF;
  display: flex;
  align-items: center;
}

.home-service-carousel-card-badge-image {
  width: 45%;
  margin: 0 auto;
}

.home-service-carousel-card-data-container {
  padding: 0 15px;
}

.home-service-carousel-card-price-text {
  font-size: 12px;
  color: var(--secondary-gray);
  margin-top: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.home-service-carousel-card-price {
  font-size: 18px;
  font-weight: bold;
}

.home-service-carousel-more-text {
  margin-top: 15px;
  cursor: pointer;
}

.home-service-carousel-button {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  border: 1px solid var(--main-yellow);
  font-size: 30px;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-weight: lighter;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 5px;
  position: absolute;
  bottom: 10px;
  right: 15px;
}

.home-carousel-card-buttons-container {
  position: absolute;
  bottom: 0;
  right: 15px;
}

.home-carousel-card-buttons-container .checkout-service-card-footer-add-buttons-container {
  margin-right: 5px;
}

.home-carousel-card-buttons-container .checkout-service-card-footer-add-buttons-container .checkout-service-card-footer-numbers-container {
  min-width: 24px;
  margin: 0 5px;
}

.home-carousel-card-buttons-container .checkout-service-card-footer-add-buttons-container .ant-image img {
  height: 14px;
}

.product-added-notification-message {
  border: 3px solid var(--main-green-correct);
  border-radius: 10px;
  width: auto;
}

.error-notification-message {
  border: 3px solid var(--main-red-error);
}

.product-added-notification-message div {
  margin: 0;
}

.product-added-notification-message .ant-notification-notice-close {
  top: 19px;
}

.product-added-notification-message .ant-notification-notice-message {
  margin-right: 15px;
  font-weight: 500;
}


@media only screen and (min-width: 1024px) {
  .home-service-carousel-card-container {
    min-width: 370px;
    max-width: 370px;
    height: 480px;
  }

  .home-service-carousel-card-image {
    height: 240px;
  }

  .home-service-carousel-card-title {
    font-size: 18px !important;
    margin: 12px 0 0 0 !important;
  }

  .home-service-carousel-card-subtitle {
    font-size: 12px;
  }

  .home-service-carousel-card-description {
    font-size: 14px;
  }

  .home-service-carousel-card-price {
    font-size: 22px;
  }

  .home-service-carousel-card-data-container {
    padding: 0 20px;
  }

  .home-carousel-card-buttons-container {
    right: 25px;
  }

  .home-carousel-card-buttons-container .checkout-service-card-footer-add-buttons-container {
    margin-right: 20px;
  }
}