.login-header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding: 20px;
  background-color: #FFFFFF;
}

.login-header-logo-container-mobile .ant-typography {
  display: flex;
  align-items: center;
}

.login-header-logo-mobile {
  width: 79px !important;
  height: 28px !important;
}

.login-have-account {
  margin: 20px;
  color: #C5CEE0;
  display: none;
}

.login-header-button-container {
  display: flex;
  align-items: center;
}

@media only screen and (min-width: 992px) {
  .login-have-account {
    display: block;
  }

  .login-header-logo-container-mobile {
    display: none;
  }

  .login-header-container {
    justify-content: flex-end;
  }
}