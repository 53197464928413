.payment-container {
  min-height: calc(100vh - 100px);
}

#braintree-drop-in-div {
  width: 95%;
  max-width: 420px;
  margin: 0 auto;
}

.braintreePayButton {
  background-color: rgb(20, 167, 20);
  border: none;
  margin: 0 auto;
  width: 95%;
  max-width: 420px;
  height: 45px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 500;
}

.payment-spin-container {
  width: 100%;
  height: 50vh;
}

.terms-text {
  max-width: 400px;
  text-align: center;
  margin: 60px auto;
  font-size: 12px;
}