.how-to-carousel-title {
  color: var(--main-yellow)
}

.how-to-carousel-container {
  height: auto;
  min-height: 250px;
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  overflow-y: hidden;
  width: calc(100% + 40px);
  max-width: 1300px;
  margin-top: 10px;
  position: absolute;
  padding-left: 20px;
  left: -20px;
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.max-width570 {
  max-width: 570px;
}

.how-to-carousel-container::-webkit-scrollbar {
  display: none;
}

@media only screen and (min-width: 768px) {
  .how-to-carousel-container {
    width: calc(100% + 160px);
    left: -80px;
    padding: 0 10px 0 80px;
  }
}

@media only screen and (min-width: 1280px) {
  .how-to-carousel-container-travel-insurance {
    max-width: 60%;
  }
}

@media only screen and (min-width: 1440px) {
  .how-to-carousel-container-travel-insurance {
    max-width: 670px;
  }
}