.loading-page-container {
  height: 100vh;
  /* background-color: rgb(41, 40, 40); */
}

.loading-page-notext-logo {
  width: 130px;
  animation-name: bounce;
  animation-duration: 0.8s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.5, 0.05, 0.795, 0.035);
}

@keyframes bounce {
  from {
    transform: translateY(0) scale(1);
  }

  to {
    transform: translateY(70px) scale(1, 0.8);
  }
}

.loading-page-notext-logo-shadow {
  width: 80px;
  margin-left: 25px;
  height: 5px;
  background: radial-gradient(closest-side at 50% 50%, rgba(150, 150, 150, 1), rgba(150, 150, 150, 0.05));
  margin-top: 33px;
  animation-name: grow;
  animation-duration: 0.8s;
  animation-direction: alternate;
  animation-timing-function: cubic-bezier(0.5, 0.05, 0.795, 0.035);
  animation-iteration-count: infinite;
}

@keyframes grow {
  from {
    transform: scale(0.2, 0.5);
  }

  to {
    transform: scale(1.5, 0.8);
  }
}

.loading-page-text-logo {
  height: 60px;
  margin: 55px 0 0 5px;
}

.loading-page-notext-logo-shine {
  position: absolute;
  top: 0;
  width: 20px;
  height: 60px;
  top: 55px;
  background: rgba(255, 255, 255, 0.1);
  animation-name: shine;
  animation-duration: 2.8s;
  animation-iteration-count: infinite;
}

@keyframes shine {
  0% {
    transform: skewX(-50deg) translateX(0);
  }

  100% {
    transform: skewX(-50deg) translateX(560px);
  }
}

.loading-page-text-logo-container {
  display: none;
}


@media only screen and (min-width: 768px) {

  .loading-page-notext-logo {
    width: 80px;
  }

  @keyframes bounce {
    from {
      transform: translateY(0) scale(1);
    }

    to {
      transform: translateY(50px) scale(1, 0.8);
    }
  }

  .loading-page-notext-logo-shadow {
    width: 40px;
    margin-left: 20px;
    margin-top: 25px;
  }

  .loading-page-text-logo-container {
    display: block;
  }
}

@media only screen and (min-width: 1256px) {
  .loading-page-notext-logo {
    width: 120px;
  }

  @keyframes bounce {
    from {
      transform: translateY(0) scale(1);
    }

    to {
      transform: translateY(70px) scale(1, 0.8);
    }
  }

  .loading-page-notext-logo-shadow {
    width: 60px;
    margin-left: 27px;
    margin-top: 35px;
  }

  .loading-page-text-logo {
    height: 80px;
    margin: 85px 0 0 8px;
  }
}