.pre-checkout-content-button-container {
  display: flex;
  justify-content: center;
  margin: 60px 0 70px;
}

.pre-checkout-content-button-container button:hover {
  background-color: var(--dark-yellow);
}

.pre-checkout-add-all-services-button-container {
  display: none;
}

@media only screen and (min-width: 768px) {
  .pre-checkout-add-all-services-button-container {
    display: flex;
    width: 100%;
    padding-left: 55px;
    max-width: 1340px;
    margin: 30px auto;
  }

  .pre-checkout-cards-container {
    padding: 0 55px;
    display: flex;
  }
}

@media only screen and (min-width: 980px) {
  .pre-checkout-cards-container {
    padding: 0 100px;
    margin: 0 auto;
    width: 100%;
    max-width: 1370px;
  }

  .pre-checkout-add-all-services-button-container {
    padding-left: 100px;
  }
}