.modal-lang-container {
  width: 90% !important;
  max-width: 970px;
}

.modal-lang-container .ant-modal-content {
  border-radius: 15px !important;
  padding-top: 70px;
}

.modal-lang-container .ant-modal-content .ant-modal-header {
  display: none;
}

.modal-lang-container .ant-modal-content .ant-modal-footer {
  display: none;
}

.modal-lang-container .ant-modal-content .ant-modal-close {
  left: 10px;
  top: 10px;
  color: var(--main-black);
}

@media only screen and (min-width: 768px) {
  .modal-lang-container {
    width: 70% !important;
  }
}