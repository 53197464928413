.main-layout-container {
  background-color: #FFFFFF;
  position: relative;
  overflow: hidden;
}

.home-header {
  min-height: 268px;
  height: auto;
  padding: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  position: relative;
}

.home-footer {
  background-color: #FFFFFF;
}

.home-header-image-mask {
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(22, 22, 22, 0.2) 0%, rgba(22, 22, 22, 0.8) 100%);
  top: 0;
}

.home-header-title {
  font-size: 28px !important;
  color: #FFFFFF !important;
  padding: 30px 20px 0 24px;
  text-align: center;
  line-height: 40px !important;
  position: relative;
  z-index: 1;
  margin-bottom: 1em !important;
}

.login-carousel-image {
  height: 100vh;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.home-content-container {
  padding: 0;
}

.home-service-info-responsive-container-desktop {
  display: none;
  justify-content: space-around;
  max-width: 1000px;
  margin: 0 auto;
  line-height: auto !important;
  margin-bottom: 90px;
  /* border: 1px solid red; */
}

.home-service-info-responsive-container-desktop .home-service-info-description-container {
  line-height: 20px;
}

@media only screen and (min-width: 768px) {
  .home-header {
    min-height: 600px;
    padding: 110px 0 0;
  }

  .home-header-title {
    margin-top: 10px;
  }

  .home-service-info-responsive-container-desktop {
    display: flex;
    flex-wrap: wrap;
  }
}

@media only screen and (min-width: 1024px) {
  .home-header {
    min-height: 824px;
  }

  .home-header-title {
    font-size: 36px !important;
    font-weight: bold !important;
  }
}