.profile-data-item-container .ant-input-disabled {
  color: var(--main-black);
  padding: 9px 0;
  border: none;
  cursor: default !important;
  background-color: #FFFFFF;
}

.profile-data-item-container span {
  color: var(--secondary-gray);
  font-size: 12px;
  font-weight: 500;
}

.profile-data-item-input {
  font-size: 18px;
  font-weight: bold;
  color: var(--main-black);
  margin: 10px 0 25px 0;
  text-overflow: ellipsis;
  padding: 9px 11px;
  border: 1px solid var(--light-border);
  border-radius: 5px;
  cursor: pointer;
  margin-right: 4px;
}

.profile-data-item-input::placeholder {
  color: var(--main-black);
}

.profile-data-item-container .ant-row {
  display: flex;
  justify-content: space-between;
}

.profile-data-item-input-phone {
  width: calc(100% - 110px);
}