.cart-sider-menu-invisible,
.cart-sider-menu {
  width: 100vw;
  height: 100vh;
  background-color: #FFFFFF;
  position: fixed;
  top: 0;
  left: 0;
  transition: transform ease-in-out .2s;
  z-index: 50;
  padding: 40px 20px 130px;
}

.cart-sider-menu-invisible {
  transform: translateX(100vw);
}

.cart-sider-menu-bg {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: var(--main-black);
  opacity: 0.85 !important;
  z-index: 49;
  display: flex;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  display: none;
}

.cart-sider-menu-bg-content-container {
  width: 50vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cart-sider-logo {
  position: absolute;
  top: -21px;
  left: 1px;
  width: 120px;
  /* border: 1px solid red; */
}

.cart-sider-menu-bg-text-container {
  color: #FFFFFF;
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: .5px;
  line-height: 25px;
}

.cart-sider-menu-bg-text-underline {
  height: 1px;
  width: 190px;
  background-color: var(--main-yellow);
}

.cart-sider-content-container {
  width: 100%;
  height: 100%;
}

.cart-sider-content-header {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.cart-sider-content-header div {
  font-size: 15px;
  font-weight: 500;
  color: var(--secondary-gray);
  height: 32px;
}

.cart-sider-content-products-container {
  width: 100%;
  padding-top: 15px;
  height: calc(100% - 140px);
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.cart-sider-content-products-container::-webkit-scrollbar {
  display: none;
}

.cart-sider-content-amount-container {
  border-top: 1px solid #D1D1D1;
  width: calc(100% + 40px);
  margin-left: -20px;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
  font-weight: 500;
  color: var(--main-black);
  padding: 5px 30px;
}

.cart-sider-content-amount-container div:nth-child(2) {
  font-size: 30px;
  font-weight: 700;
}

.cart-sider-content-button-checkout-container {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
}

.cart-sider-content-close-button {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  border: 1px solid var(--main-gray);
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-size: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(45deg);
  color: var(--main-black);
}

.cart-sider-content-close-button:hover {
  background-color: #FFFFFF;
  color: var(--main-black);
  border: 1px solid var(--main-gray);
}

.cart-sider-content-close-button span {
  display: flex;
  align-items: center;
  padding-bottom: 5px;
}

.cart-sider-content-empty-cart-container .ant-image {
  width: 100%;
}

.cart-sider-content-empty-cart-image {
  width: 95%;
  margin: 9vh auto 0;
  max-width: 350px;
}

.cart-sider-content-empty-cart-text {
  margin-top: 40px;
  font-size: 22px;
  font-weight: 700;
  line-height: 30px;
  display: flex;
  justify-content: center;
  text-align: center;
}

@media only screen and (min-width: 768px) {

  .cart-sider-menu-invisible,
  .cart-sider-menu {
    width: 50vw;
    left: 50vw;
    padding: 65px 30px 80px;
    transition: transform ease-in-out .3s;
  }

  .cart-sider-menu-bg {
    display: flex;
  }

  .cart-sider-menu-invisible {
    transform: translateX(50vw);
  }

  .cart-sider-menu-header-container {
    z-index: 100 !important;
  }

  .cart-sider-logo {
    display: none;
  }

  .cart-sider-content-amount-container {
    width: calc(100% + 60px);
    margin-left: -30px;
    height: 120px;
  }

  .cart-sider-content-products-container {
    height: calc(100% - 200px);
  }
}

@media only screen and (min-width: 960px) {

  .cart-sider-menu-invisible,
  .cart-sider-menu {
    width: 40vw;
    left: 60vw;
  }

  .cart-sider-menu-invisible {
    transform: translateX(40vw);
  }

  .cart-sider-menu-bg-content-container {
    width: 60vw;
  }

  .cart-sider-content-empty-cart-image {
    max-width: 550px;
  }

  .cart-sider-content-empty-cart-text {
    margin-top: 0px;
  }
}

@media only screen and (min-width: 1024px) {

  .cart-sider-menu-invisible,
  .cart-sider-menu {
    padding: 65px 40px 80px;
  }

  .cart-sider-content-amount-container {
    width: calc(100% + 80px);
    margin-left: -40px;
  }
}

@media only screen and (min-width: 1340px) {

  .cart-sider-menu-invisible,
  .cart-sider-menu {
    width: 536px;
    left: calc(100vw - 536px);
    padding: 65px 60px 80px;
  }

  .cart-sider-menu-invisible {
    transform: translateX(536px);
  }

  .cart-sider-menu-bg-content-container {
    width: calc(100vw - 536px);
  }

  .cart-sider-content-amount-container {
    width: calc(100% + 120px);
    margin-left: -60px;
    height: 120px;
  }
}