.modal-lang-content-title {
  font-size: 15px !important;
  margin: 30px 0;
}

.modal-lang-content-container {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 30px;
}

.modal-lang-content-lang-container,
.modal-lang-content-currency-container {
  border: 1px solid rgba(0, 0, 0, 0.096);
  min-width: 111px;
  height: 45px;
  border-radius: 5px;
  margin: 30px 20px 0 0;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
}

.modal-lang-content-lang-container-active,
.modal-lang-content-currency-container-active,
.modal-lang-content-lang-container:hover,
.modal-lang-content-currency-container:hover {
  border: 1px solid var(--main-black);
}

.modal-lang-content-lang-container div,
.modal-lang-content-currency-container div {
  font-size: 12px;
  font-weight: 500;
}

.modal-lang-content-lang-container div:nth-child(2),
.modal-lang-content-currency-container div:nth-child(2) {
  color: var(--secondary-gray);
}