.home-faq-container {
  position: relative;
  width: 100%;
  max-width: 1305px;
  margin: 0 auto;
}

.home-faq-title {
  font-size: 36px !important;
  font-weight: bold !important;
  margin: 40px 0 !important;
}

.home-faq-container .ant-collapse {
  background-color: #FFFFFF;
  border: none;
}

.home-faq-panel {
  border-radius: 15px !important;
  border: none !important;
  margin: 20px 0;
  overflow: hidden;
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.12);
  z-index: 1;
  position: relative;
  backdrop-filter: blur(20px) !important;
  background: rgba(255, 255, 255, 0.6);
}

.ant-collapse-item-active {
  background-color: #FFFFFF;
}

.home-faq-panel .ant-collapse-header {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 15px 13px 15px 20px !important;
  font-weight: bold;
  font-size: 15px;
}

.home-faq-panel .ant-collapse-header span {
  min-width: 40px !important;
  height: 40px;
  border-radius: 50%;
  border: 2px solid var(--main-yellow);
  background-color: var(--main-yellow);
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.home-faq-panel .ant-collapse-header span::after {
  width: 16px;
  height: 16px;
  content: '+';
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-weight: lighter;
  padding-bottom: 3px;
}

.ant-collapse-item-active .ant-collapse-header span {
  background-color: white;
}

.home-faq-panel .ant-collapse-header span svg {
  display: none;
}

.home-faq-container .ant-collapse-header span {
  transition: linear .1s;
}

.home-faq-container .ant-collapse-item-active .ant-collapse-arrow {
  transform: rotate(45deg);
}

.home-faq-panel .ant-collapse-content {
  font-size: 12px;
  border-top: none;
}

.home-faq-panel .ant-collapse-content .ant-collapse-content-box {
  padding: 0 20px 16px;
}

.home-faq-panel .ant-collapse-header::after,
.home-faq-panel .ant-collapse-header::before {
  display: none !important;
}

.home-faq-more-text {
  font-size: 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.home-faq-more-symbol {
  color: var(--main-yellow);
  font-size: 18px;
  margin-right: 7px;
  padding-top: 2px;
}

.home-faq-desktop-image {
  width: 60%;
  max-width: 805px;
  height: 500px;
  /* background-image: url('../../../assets/images/home/home-faq-image.png'); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 90px;
  right: 20px;
  border-radius: 20px;
  display: none;
}

@media only screen and (min-width: 768px) {
  .home-faq-container {
    min-height: 700px;
    padding: 0 20px;
  }

  .home-faq-panel .ant-collapse-header {
    font-size: 18px;
  }

  .home-faq-panel .ant-collapse-content {
    font-size: 14px;
  }

  .home-faq-container .ant-collapse {
    width: 60%;
    max-width: 770px;
  }

  .faqs-page-container {
    min-height: 100px;
  }

  .faqs-page-container .ant-collapse {
    margin: 0 auto;
  }

  .home-faq-desktop-image {
    display: block;
  }
}