.prefix-phone-container input {
  height: 48px;
  width: 90px !important;
  font-weight: 500;
  font-size: 14px !important;
  padding-left: 48px !important;
  padding-right: 3px !important;
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.prefix-phone-container .flag-dropdown .selected-flag {
  padding: 0;
  padding-left: 10px !important;
}

.prefix-phone-container .flag-dropdown .selected-flag::before{
  width: 90px;
  display: flex;
  justify-content: center !important;
  border-color: transparent !important;
  box-shadow: none !important;
}

.prefix-phone-container .flag-dropdown .country-list .search input {
  height: 30px;
  width: 92% !important;
  padding: 5px !important;
}

.prefix-phone-search {
  border-color: var(--light-border) !important;
  caret-color: transparent !important;
}

.prefix-phone-search:focus {
  border-color: var(--light-border) !important;
  box-shadow: none !important;
  outline: none !important;
}

.prefix-phone-container-profile {
  width: 100px;
}

.prefix-phone-container-profile input {
  width: 100px !important;
}

.prefix-phone-container-profile .prefix-phone-search {
  font-size: 18px !important;
  font-weight: 700 !important;
  color: var(--main-black) !important;
  font-family: "Poppins";
  width: 100px;
}

.prefix-phone-container-profile-disabled .prefix-phone-search {
    border: none !important;
    padding-bottom: 20px !important;
}

.prefix-phone-container-profile-disabled .flag-dropdown {
  padding-bottom: 2px !important;
}

.prefix-phone-container-profile-disabled .flag-dropdown .selected-flag .flag .arrow {
  display: none;
}