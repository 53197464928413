.product-detail-header {
  min-height: 650px;
  padding: 0;
  position: relative;
  height: 100%;
}

.product-detail-header .home-header-image-mask {
  position: absolute;
  left: 0;
  top: 0;
  background:
    linear-gradient(180deg, rgba(22, 22, 22, 0.4) 0%,
      rgba(22, 22, 22, 0.8) 100%);
}

.product-detail-content-container {
  background-color: #FFFFFF;
  padding: 0 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.product-detail-content-container .home-service-carousel-container {
  width: calc(100% + 50px);
  max-width: calc(100% + 50px);
  margin-left: -25px;
  overflow: hidden;
}

.product-detail-content-container .home-service-carousel {
  margin-left: 0 !important;
  width: 100%;
}

.product-detail-content-container .home-service-carousel-title {
  padding-left: 25px;
}

.product-detail-service-card-container .home-service-card-container .home-service-text-container {
  max-width: 470px;
}

.product-detail-footer .newsletter-container {
  margin-top: 0;
}

.product-detail-travel-insurance-form-space {
  height: 990px;
}

@media only screen and (min-width: 768px) {
  .product-detail-header {
    min-height: 720px;
  }

  .product-detail-service-card-container {
    width: 100%;
    padding: 50px 40px 0;
    position: relative;
    color: var(--primary-black);
  }

  .product-detail-service-card-container .home-service-card-container {
    display: flex;
    justify-content: center;
  }

  .product-detail-content-container .home-service-carousel-title {
    padding-left: 80px;
  }

  .product-detail-content-container .home-service-carousel {
    padding-left: 55px;
  }

  .product-detail-travel-insurance-form-space {
    height: 970px;
  }
}

@media only screen and (min-width: 1256px) {
  .product-detail-service-card-container .home-service-card-container .home-service-text-container {
    padding: 0;
    max-width: 470px;
  }

  .product-detail-service-card-container .home-service-card-container {
    justify-content: space-between;
  }

  .product-detail-content-container .home-service-carousel-container {
    margin: 0 auto;
    max-width: 1206px;
  }

  .product-detail-content-container .home-service-carousel-title {
    padding-left: 25px;
  }

  .product-detail-content-container .home-service-carousel {
    padding-left: 5px;
  }
}

@media only screen and (min-width: 1280px) {
  .product-detail-travel-insurance-form-space {
    height: 180px;
  }
}

@media only screen and (min-width: 1440px) {
  .product-detail-header {
    min-height: 780px;
  }

  .travel-insurance-header-height {
    min-height: 540px !important;
  }

  .product-detail-travel-insurance-form-space {
    height: 340px;
  }
}