.home-flight-search-input-container {
  position: relative;
}

.home-flight-search-input-container>input {
  padding-left: 50px !important;
}

.home-flight-search-input {
  height: 70px;
  border-radius: 100px;
  font-size: 18px;
  font-weight: bold;
  padding: 20px 39px 0 39px;
  width: 100%;
  margin: 5px 0;
  color: var(--main-black);
  border: 1px solid #EAEAEA;
  background-color: transparent;
}

.home-flight-search-input input {
  font-weight: 700;
  text-transform: capitalize;
}

.home-flight-search-input .home-flight-search-input-option-content-container {
  border: none;
  justify-content: flex-start;
}

.home-flight-search-input-hashtag {
  position: absolute;
  left: 39px;
  top: 42px;
  font-size: 12px;
  color: var(--secondary-gray);
}

.home-flight-search-input-flight-number .home-flight-search-input-option-content-container {
  justify-content: flex-end;
}


.home-flight-search-input .home-flight-search-input-option-content-name {
  width: auto;
  max-width: calc(100% - 30px);
}

.home-flight-search-input .home-flight-search-input-option-content-name div:nth-child(1) {
  display: none;
}

.home-flight-search-input-flight-number .home-flight-search-input-option-content-name>div {
  display: block !important;
  line-height: 30px;
  font-weight: 700;
  font-size: 18px;
  color: var(--main-black);
}

.home-flight-search-input-flight-number .home-flight-search-input-option-content-container {
  flex-direction: row-reverse;
}

.home-flight-search-input-flight-number .home-flight-search-input-option-content-iata {
  font-size: 15px !important;
  line-height: 31px !important;
}

.home-flight-search-input .home-flight-search-input-option-content-name div:nth-child(2) {
  line-height: 30px;
  font-weight: 700;
  font-size: 18px;
  color: var(--main-black);
}

.home-flight-search-input .home-flight-search-input-option-content-iata {
  display: flex;
  justify-content: flex-start;
  font-size: 10px;
  line-height: 35px;
  font-weight: 400;
  letter-spacing: 1px;
  color: var(--secondary-gray);
  padding-left: 4px;
}

.home-flight-search-input-flight-number .home-flight-search-input-option-content-iata {
  padding-left: 0px;
}

.home-flight-search-input .ant-select-selector {
  padding: 0 !important;
  border: none !important;
  margin-top: 10px;
  width: 100%;
}

.home-flight-search-input .ant-select-selector .ant-select-selection-search {
  width: 75% !important;
  left: 0;
}

.home-flight-search-input .ant-select-arrow {
  display: none;
}

.home-flight-search-input-label {
  position: absolute;
  left: 40px;
  top: 15px;
  font-size: 10px;
  color: var(--secondary-gray);
}

.home-flight-search-input-label::first-letter {
  text-transform: uppercase;
}

.home-flight-search-input-container .ant-image {
  position: absolute;
  right: 47px;
  top: 35px;
  width: 18px;
}

.home-flight-search-input-dropdown {
  border-radius: 15px;
  height: 198px;
  min-width: 65% !important;
  max-width: 65% !important;
  background-color: transparent;
  box-shadow: none !important;
  padding-right:10px;
}

.home-flight-search-input-dropdown>div {
  border-radius: 15px !important;
  max-height: 168px;
  background-color: white;
  box-shadow: 1px 5px 10px 0px rgba(0, 0, 0, 0.12);
  overflow: hidden;
}

.home-flight-search-input-dropdown .rc-virtual-list-scrollbar {
  display: none;
}

.home-flight-search-input-option {
  padding: 0px 15px;
  width: 100%;
}

.home-flight-search-input-option>div {
  height: 55px;
}

.home-flight-search-input-option .home-flight-search-input-option-content-container{
  align-items: center;
}

.home-flight-search-input-option-content-container {
  height: 100%;
  border-bottom: 1px solid #D1D1D1;
  display: flex;
  justify-content: space-between;
}

.home-flight-search-input-option-content-name {
  width: calc(100% - 30px);
}

.home-flight-search-input-option-content-name>div {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 13px;
  font-weight: 500;
}


.home-flight-search-input-option-content-name div:nth-child(2) {
  font-size: 10px;
  font-weight: 400;
  color: var(--secondary-gray);
  line-height: 10px;
}



.home-flight-search-input-option-content-iata {
  width: 30px;
  font-size: 12px;
  color: var(--main-yellow);
  font-weight: 400;
  text-align: end;
}

.home-flight-search-input .home-flight-search-input-option-content-iata {
  width: auto !important;
  margin-right: 2px;
}

.search-vip-lounge-container .home-flight-search-input-option-content-container {
  justify-content: flex-start !important;
}

@media only screen and (min-width: 768px) {
  .home-flight-search-input {
    margin: 0;
    height: 100%;
    width: 100%;
    padding-right: 5px;
    padding-top: 24px;
    border: none;
  }

  .home-flight-search-input-container {
    width: 30%;
  }

  .home-flight-date-input-container {
    width: 30%;
  }

  .home-flight-date-input-container .home-flight-search-input {
    padding: 0 0 0 10px;
  }

  .home-flight-search-input-icon {
    position: absolute;
    left: 80px;
    top: 7px;
  }

  .home-flight-search-input-dropdown {
    min-width: 35% !important;
    max-width: 35% !important;
  }
  
  .home-flight-search-input-label {
    top: 5px;
  }

  .home-flight-search-input-flight-number {
    padding-left: 39px !important;
  }

  .home-flight-search-input-flight-number .ant-select-selector {
    margin-top: 20px;
  }

  .home-flight-search-input-hashtag {
    top: 36px;
  }
}

@media only screen and (min-width: 1024px) {
  .home-flight-search-input-icon {
    left: 100px;
  }
  
  .search-vip-lounge-container .home-flight-search-input {
    left: 25px;
  }

  .home-flight-date-input-container .home-flight-search-input {
    padding: 0 0 0 30px;
  }

  .home-flight-search-input {
    padding-left: 60px;
    font-size: 22px;
  }

  .home-flight-search-input-dropdown {
    min-width: 400px !important;
    max-width: 400px !important;
  }

  .home-flight-search-input .home-flight-search-input-option-content-name div:nth-child(2) {
    font-size: 22px;
  }

  .home-flight-search-input .home-flight-search-input-option-content-iata {
    font-size: 12px;
    line-height: 38px;
    letter-spacing: .5px;
  }

  .home-flight-search-input-flight-number .home-flight-search-input-option-content-name>div {
    font-size: 22px;
  }

  .home-flight-search-input-flight-number .home-flight-search-input-option-content-iata {
    font-size: 16px !important;
    line-height: 33px !important;
  }

  .home-flight-search-input-hashtag {
    left: 38px;
    top: 37px;
    font-size: 14px;
  }
}