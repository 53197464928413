@font-face {
  font-family: 'Roboto';
  src: url('./assets/font/Roboto-Regular.ttf');
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/font/Roboto-Bold.ttf');
  font-weight: bold;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/font/poppins/Poppins-Regular-400.ttf');
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/font/poppins/Poppins-Medium-500.ttf');
  font-weight: 500;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/font/poppins/Poppins-SemiBold-600.ttf');
  font-weight: 600;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/font/poppins/Poppins-Bold-700.ttf');
  font-weight: bold;
}

:root {
  --main-gray: #777679;
  --secondary-gray: #ABAAAB;
  --main-black: #161616;
  --light-border: #E5E9F1;
  --main-yellow: #FFC629;
  --secondary-yellow: #FEECC4;
  --dark-yellow: #F5B400;
  --dropdown-yellow: #feecc4ad;
  --main-green-correct: #68e097;
  --secondary-green-correct: #68e0971A;
  --main-red-error: #EB396F;
  --secondary-red-error: #eb396f3e;
}

body {
  background-color: #FFFFFF;
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Notification error class */
.notification-error {
  background-color: #de5246 !important;
  color: #FAFAFA;
}

.notification-error .ant-notification-notice-message,
.notification-error .ant-notification-notice-close {
  color: #FAFAFA;
  font-weight: bold;
}

/* Notification success class */
.notification-success {
  background-color: #52BF90 !important;
  color: #FAFAFA;
}

.notification-success .ant-notification-notice-message,
.notification-success .ant-notification-notice-close {
  color: #FAFAFA;
  font-weight: bold;
}

.ant-layout-header {
  line-height: 50px;
  background-color: #FFFFFF;
}

@media only screen and (max-width: 767px) {
  .ant-layout-has-sider>aside {
    z-index: 10;
    position: fixed;
    top: 0px;
    left: 0px;
    height: 100%;
  }

  .ant-layout-sider-collapsed~.menu-left-overlay {
    display: none;
  }

  .menu-left-overlay {
    width: 100vw;
    height: 100vh;
    background: #000c17;
    opacity: 0.5;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9
  }
}

.ant-scrolling-effect {
  width: 100% !important;
}

.ant-layout-content {
  padding: 25px;
}

.ant-list-item-meta-title {
  margin: 0;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.d-flex {
  display: flex;
}

.d-none {
  display: none;
}

.mt-10 {
  margin-top: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mv-15 {
  margin: 15px 0;
}

.mr-10 {
  margin-right: 10px;
}

.mr-20 {
  margin-right: 20px;
}

.w-100 {
  width: 100%;
}

.hidden {
  display: none;
}

.padding-30 {
  padding: 30px;
}

.maxWidth-100 {
  max-width: 100% !important;
}

.flex-sb {
  display: flex;
  justify-content: space-between;
}

.zIndex100 {
  z-index: 100 !important;
}

.icon-small {
  font-size: 16px !important;
  cursor: pointer;
}

.icon-medium {
  font-size: 22px !important;
  cursor: pointer;
}

.icon-large {
  font-size: 24px !important;
  cursor: pointer;
}

.h-100 {
  height: 100%;
}

.min-h-100vh {
  min-height: 100vh;
}

.p-relative {
  position: relative;
}

.fw-bold {
  font-weight: bold;
}

.f-col-c {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.user-right>span {
  margin: 5px;
}

.user-right {
  font-size: 24px;
}

.container-spinner-positioning {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 38, 84, 0.2);
  z-index: 1;
}

.spinner-positioning {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.spinner-positioning .ant-spin-dot-item {
  background-color: #002654 !important;
}

.cursor-pointer {
  cursor: pointer;
}

.actions-container {
  display: flex;
  justify-content: space-around;
}

.list-actions {
  color: #000;
}

.ant-list-header {
  background-color: #FFFFFF;
}

.mb-1em {
  margin-bottom: 1em;
}

.mb-2em {
  margin-bottom: 2em;
}

.mt-1em {
  margin-top: 1em;
}

.mr-10px {
  margin-right: 10px;
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.bg-white {
  background-color: #FFFFFF !important;
}

.ant-select-item-option-selected {
  background-color: #EAEAEA !important;
}

.ant-select-selector,
.ant-picker-focused {
  box-shadow: none !important;
}

.ant-select-dropdown {
  border-radius: 10px;
}

.ant-picker:hover {
  border: 1px solid #EAEAEA !important;
  cursor: pointer;
}

@media only screen and (min-width: 768px) {
  .ant-picker:hover {
    border: none !important;
  }
}

input[type=number] {
  -moz-appearance: textfield;
}

.ant-input:focus,
.ant-input:hover,
.ant-input-password,
.ant-input-password:hover {
  border-color: var(--light-border) !important;
  box-shadow: none !important;
}

.ant-picker-dropdown .ant-picker-panel-container {
  border-radius: 15px;
}

.ant-picker-dropdown .ant-picker-date-panel {
  width: 90vw;
  max-width: 350px !important;
}

.ant-picker-content {
  width: 100% !important;
}

.ant-picker-dropdown .ant-picker-panel-container div,
.ant-picker-dropdown .ant-picker-panel-container th {
  font-weight: 500;
}

.ant-picker-dropdown .ant-picker-panel-container button:hover,
.ant-picker-today-btn,
.ant-picker-today-btn:hover {
  color: var(--main-yellow);
}

.ant-picker-cell:hover .ant-picker-cell-inner {
  background-color: var(--secondary-yellow) !important;
  color: var(--main-yellow);
}

.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-range-end .ant-picker-cell-inner,
.ant-picker-cell-range-start .ant-picker-cell-inner {
  background-color: var(--main-yellow) !important;
}

.ant-picker-cell-in-range::before,
.ant-picker-cell-selected::before,
.ant-picker-cell-range-start::before {
  height: 86%;
  background-color: var(--secondary-yellow) !important;
}

.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: 1px solid var(--main-yellow) !important;
  border-radius: 6px !important;
}

.ant-picker-cell .ant-picker-cell-inner {
  width: 40px;
  height: 40px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-picker-cell-disabled::before {
  background: none !important;
}

.ant-picker-clear {
  display: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.ant-select-disabled input,
.ant-switch-loading, .ant-switch-disabled .ant-switch-handle,
.ant-switch-loading, .ant-switch-disabled,
.react-tel-input :disabled,
.ant-picker-input>input[disabled],
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-picker.ant-picker-disabled {
  cursor: default !important;
}