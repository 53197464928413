.how-to-carousel-step-container {
  min-width: 270px;
  max-width: 270px;
  margin-right: 10px;
}

.how-to-carousel-step-image-container {
  width: 100%;
  height: 140px;
  border-radius: 15px;
  overflow: hidden;
  margin-bottom: 10px;
}

.how-to-carousel-step-image-container .ant-image {
  width: 100%;
  height: 100%;
}

.how-to-carousel-step-image {
  width: 100%;
  height: 100%; 
  object-fit: cover;
}

.how-to-carousel-step-title {
  font-size: 15px !important;
  line-height: 10px !important;
}

.how-to-carousel-step-line-separator {
  width: 3px;
  height: 1px;
  background-color: var(--main-yellow);
  margin-top: 4px;
  margin-bottom: 3px;
}

.how-to-carousel-step-description {
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 16px !important;
  color: var(--secondary-gray);
}

.how-to-carousel-step-title-insurance {
  color: #FFFFFF;
}

@media only screen and (min-width: 768px) {
  .how-to-carousel-step-container {
    margin-right: 30px;
  }
}

@media only screen and (min-width: 1280px) {
  .how-to-carousel-step-title-insurance {
    color: var(--main-black);
  }
}

@media only screen and (min-width: 1440px) {
  .how-to-carousel-step-container {
    min-width: 265px;
    max-width: 265px;
  }
}