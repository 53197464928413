.faqs-main-page-container {
  display: flex;
  justify-content: center;
}

.faqs-page-title {
  font-size: 28px !important;
  font-weight: 700 !important;
}

.faqs-page-subtitle {
  font-size: 12px;
}

.faqs-page-content-container {
  width: 100%;
  max-width: 800px;
}

.faqs-page-section-title-container {
  display: flex;
  align-items: center;
  margin-top: 40px;
}

.faqs-page-section-title-container .ant-image {
  margin-right: 10px;
}

.faqs-page-section-title {
  font-size: 18px;
  font-weight: 700;
  padding-top: 3px;
}

.faqs-page-sider-links-container {
  width: 170px;
  height: 200px;
  margin-top: 100px;
  display: none;
}

.faqs-page-sider-link {
  font-size: 15px;
  font-weight: 500;
  color: var(--main-black);
  line-height: 33px;
  width: 30px !important;
  margin-bottom: 3px;
  cursor: pointer;
}

.faqs-page-sider-links-container .active {
  border-bottom: 1px solid var(--main-yellow);
}

@media only screen and (min-width: 768px) {
  .faqs-page-title-container {
    width: 470px;
    text-align: center;
    margin: 0 auto;
  }

  .faqs-page-section-title-container {
    padding-left: 20px;
  }

  .faqs-page-content-container {
    width: calc(100% - 170px);
  }

  .faqs-page-sider-links-container {
    display: block;
  }
}

@media only screen and (min-width: 1440px) {
  .faqs-page-content-container .ant-col .home-faq-container {
    min-width: 0px;
  }

  .faqs-page-content-container {
    /* width: 70%; */
  }
}