.checkout-services-promo-container {
  margin-bottom: 30px;
  position: relative;
  margin-right: 30px;
  display: flex;
  flex-direction: column;
  max-width: 140px;
}

.checkout-services-promo-icon-container {
  position: relative;
}

.checkout-services-promo-icon-container>.ant-image {
  position: absolute;
  top: 8px;
  left: 0;
}

.checkout-services-promo-text {
  font-size: 18px;
  font-weight: 700;
}

.checkout-services-promo-text .ant-image {
  margin-right: 5px;
}

.checkout-services-promo-title {
  font-size: 16px !important;
  font-weight: 700 !important;
  color: var(--main-black) !important;
  margin-bottom: 0 !important;
}

.checkout-services-promo-description {
  font-size: 12px;
  color: var(--secondary-gray);
}

.checkout-services-promo-dropdown {
  border-radius: 15px;
  box-shadow: 0px 8px 50px 0px #00000014;
  padding: 10px;
  width: 260px !important;
}

.checkout-services-promo-dropdown .ant-select-item-option-selected {
  border-radius: 10px;
  background-color: #eaeaea86 !important;
}

.checkout-services-promo-container .ant-select-selector {
  min-width: 110px;
  max-width: 280px;
  display: flex;
  align-items: center;
  padding: 0 25px 0 20px !important;
  border: none !important;
  background-color: transparent !important;
}


.checkout-services-promo-container .ant-select-selection-item {
  width: 100%;
  overflow: hidden;
  height: 100%;
  display: flex;
  align-items: center;
  text-overflow: ellipsis;
  padding: 0 !important;
  font-weight: 700;
}

.checkout-services-promo-container .ant-select-selection-item h1 {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.checkout-services-promo-container .ant-select-selection-item div {
  color: var(--main-black);
}

.checkout-services-promo-container .ant-select-selection-item>span {
  display: none;
}

.checkout-services-promo-icon-container>div>span {
  top: 16px;
  right: 5px;
}

.checkout-services-promo-dropdown .ant-select-item-option {
  border: 2px solid #FFFFFF;
}

.checkout-services-promo-dropdown .ant-select-item-option>div>h1 {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.checkout-services-promo-dropdown .ant-select-item-option-active {
  background-color: #eaeaea86;
  border: 2px dotted #abaaab7a;
  border-radius: 10px;
}

.checkout-services-promo-code-option {
  margin-top: 10px;
  padding-top: 10px;
  border-radius: 0 !important;
  border: none !important;
  border-top: 1px solid var(--main-yellow) !important;
  background-color: #FFFFFF !important;
}

.checkout-services-promo-code-text {
  font-size: 12px;
  color: var(--main-black);
}

.checkout-services-promo-code-input {
  width: 100%;
  height: 48px;
  text-align: center;
  margin-top: 10px;
  border-radius: 5px;
}

.checkout-services-promo-code-input::placeholder {
  font-size: 9px;
  font-weight: 500;
  text-decoration: underline;
  color: var(--main-gray);
}

.checkout-services-promo-services-card-container {
  display: none;
}

.checkout-services-promo-delete {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
}

.checkout-services-promo-delete .ant-image {
  width: 17px;
  margin-top: 7px;
  cursor: pointer;
}

.checkout-services-promo-input-container {
  display: flex;
  flex-direction: column;
}

.checkout-services-promo-input-container input {
  margin: 5px 0;
  text-align: center;
  font-weight: 500;

}

.checkout-services-promo-input-submit-confirmed:hover,
.checkout-services-promo-input-submit-confirmed,
.checkout-services-promo-input-submit-confirmed:focus {
  background-color: var(--main-green-correct);
  color: white;
  font-weight: 600;
  border: none;
  cursor: default;
}

.checkout-services-promo-input-submit-error:hover,
.checkout-services-promo-input-submit-error,
.checkout-services-promo-input-submit-error:focus {
  background-color: var(--main-red-error);
  color: white;
  font-weight: 600;
  border: none;
  cursor: default;
}

@media only screen and (min-width: 768px) {
  .checkout-services-page-container {
    padding: 0 55px;
    display: flex;
    justify-content: space-between;
  }

  .checkout-services-promo-container .ant-select-selector {
    max-width: 180px;
  }
}

@media only screen and (min-width: 980px) {
  .checkout-services-page-container {
    padding: 0 100px;
    display: flex;
    justify-content: space-between;
    max-width: 1340px;
    margin: 0 auto;
  }

  .checkout-services-promo-services-container {
    width: 470px;
  }

  .checkout-services-promo-container .ant-select-selector {
    max-width: 280px;
  }
}

@media only screen and (min-width: 1180px) {
  .checkout-services-promo-services-container {
    width: 470px;
  }

  .checkout-services-promo-services-card-container {
    display: block;
    padding-bottom: 50px;
  }
}

.loading-container{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;

}

.loading-container .ant-image-img {
  width: 100px !important;
}

@media only screen and (min-width: 768px) {
  .loading-container .ant-image-img {
    width: 130px !important;
  }
}

@media only screen and (min-width: 1256px) {
  .loading-container .ant-image-img {
    width: 120px !important;
  }
}