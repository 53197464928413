.button-primary-style {
	min-width: 170px;
	height: 40px;
	color: #FFFFFF;
	background-color: var(--main-yellow);
	line-height: 21px;
	border-radius: 40px;
	font-size: 14px;
	font-weight: 700;
	border: 1px solid var(--main-yellow);
	text-transform: uppercase;
	padding: 0 30px;
	overflow: hidden;
}

.button-primary-style span {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.button-primary-style-xs {
	width: 100px;
}

.button-primary-style-xs-confirm {
	width: 100px;
	background: #68E097;
	border: 1px solid #68E097;
}

.button-primary-style-sm {
	width: 141px;
}

.button-primary-style-lg {
	height: 56px;
	font-size: 18px;
	min-width: 175px;
	margin-top: 30px;
}

.button-primary-style:hover {
	transform: scale(1.02);
	background-color: var(--dark-yellow) !important;
	border: 1px solid var(--dark-yellow) !important;
	color: #FFFFFF !important;
}

.button-primary-style[disabled] {
	background-color: #F5F5F5 !important;
	border: none !important;
	color: rgba(0, 0, 0, 0.25) !important;
	cursor: default;
}

.button-primary-style-xl {
	height: 56px;
	min-width: 205px;
	font-size: 16px;
}

.button-provider-style {
	width: 100%;
	height: 48px;
	border: 1px solid #D1D1D1;
	border-radius: 10px;
	color: var(--main-gray);
	font-size: 13px;
	font-weight: 500;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 !important;
	transition: 0s;
}

.button-provider-style-fb {
	border: 1px solid #1877F2;
}

.button-provider-style-apple {
	border: 1px solid #000000;
}

.button-provider-style:hover,
.button-provider-style:active,
.button-provider-style:focus {
	background-color: transparent !important;
	border: 1px solid #D1D1D1 !important;
	color: var(--main-gray) !important;
}

.button-provider-style-fb:hover,
.button-provider-style-fb:active,
.button-provider-style-fb:focus {
	border: 1px solid #1877F2 !important;
}

.button-provider-style-apple:hover,
.button-provider-style-apple:active,
.button-provider-style-apple:focus {
	border: 1px solid #000000 !important;
}

.provider-button-content {
	display: flex;
	align-items: center;
	width: 200px;
}

.provider-button-content .ant-image {
	margin-right: 15px;
}

.button-search-flight-style {
	width: 100%;
	height: 59px;
	border-radius: 100px;
	background-color: var(--main-yellow);
	border: none;
	text-transform: uppercase;
	font-size: 15px;
	font-weight: 500;
	color: #FFFFFF;
	margin: 25px 0;
}

.button-search-flight-style:hover {
	transform: scale(1.02);
	background-color: var(--dark-yellow) !important;
}

.button-transparent-style {
	min-width: 83px;
	height: 32px;
	color: var(--main-black);
	line-height: 18px;
	border-radius: 40px;
	font-size: 12px;
	border: 1px solid #D1D1D1;
	font-weight: 700;
	text-transform: uppercase;
	overflow: hidden;
}

.button-transparent-style-lg {
	width: 100px;
	height: 40px;
	line-height: 21px;
	font-size: 14px;
	border: 1px solid var(--secondary-gray);
	text-transform: uppercase;
}

.ant-btn:hover,
.ant-btn:focus {
	border: 1px solid var(--main-yellow);
	background-color: var(--main-yellow);
	color: #FFFFFF;
}

@media only screen and (min-width: 768px) {
	.button-search-flight-style {
		margin: 0;
		width: 70px;
		height: 70px;
		position: absolute;
		right: 12px;
		top: 62px;
		padding-top: 10px;
	}

	.button-search-flight-style:hover {
		transform: none;
	}
}

@media only screen and (min-width: 992px) {
	.button-provider-style {
		width: 232px;
	}
}