.login-carousel {
  min-height: 760px;
}

.login-sider-logo-container {
  width: 100%;
  height: 108px !important;
  display: flex;
  align-items: center;
  padding: 35px;
  font-size: 22px;
  letter-spacing: 1px;
  position: absolute;
  top: 0;
}

.login-sider-logo-container .ant-typography {
  width: 100px;
}

.login-sider-description-container {
  width: 400px;
  height: 100% !important;
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 1px;
  padding: 30px;
  padding-bottom: 90px;
  display: flex;
  align-items: flex-end;
  position: absolute;
  top: 0;
  color: #FFFFFF;
}

.login-carousel-image {
  height: 100%;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.login-carousel-image-mask {
  width: 100%;
  height: 50% !important;
  position: absolute;
  bottom: 0;
  background: linear-gradient(180deg, rgba(22, 22, 22, 0) 0%, #161616 100%);
}

.slick-dots {
  justify-content: flex-end !important;
  width: 80%;
  height: 30px;
}

.slick-dots .slick-active {
  width: 16px !important;
}

.slick-active button {
  background: var(--main-yellow) !important;
}

.slick-dots li button {
  width: 5px !important;
  height: 5px !important;
  border-radius: 50% !important;
}