.login-screen-container {
  max-width: 470px;
  margin: 0 auto;
}

.b2b-login-screen-container {
  margin-top: 5vh;
}

.login-title-container {
  min-height: 31px;
  display: flex;
  align-items: center;
  margin: 5px 0;
}

.login-title {
  font-size: 22px !important;
  line-height: 33px !important;
  color: var(--main-black);
  font-weight: 700 !important;
  margin: 0 !important;
}

.login-subtitle-container {
  height: 23px;
  margin: 10px 0;
}

.login-subtitle {
  font-size: 12px;
  line-height: 16.8px;
  color: var(--secondary-gray)
}

.ant-menu-horizontal {
  height: 40px;
  border-bottom: 2px solid #D1D1D1;
}

.ant-menu-item {
  height: 40px;
  margin: 0 10px 0 -20px;
}

.ant-menu-item-selected::after {
  border-bottom: 2px solid var(--main-yellow) !important;
}

.ant-menu-title-content {
  font-size: 13px;
  font-weight: 500;
}

.login-label {
  font-size: 12px;
  line-height: 14px;
  font-weight: 600;
  color: var(--main-black);
  margin-top: 25px;
}

.login-label-password-container {
  width: 100%;
  position: relative;
}

.login-forgot-pasword {
  position: absolute;
  right: 0;
  bottom: -14px;
  line-height: 14px;
  font-size: 12px;
  color: var(--secondary-gray);
  cursor: pointer;
}

.login-forgot-pasword:hover {
  color: var(--main-gray);
}

.login-input-box,
.login-input-box:hover,
.login-input-box:focus {
  height: 48px;
  border-radius: 5px;
  border: 1px solid var(--light-border) !important;
  font-weight: 500;
  font-size: 14px;
  margin-top: 10px;
  padding: 0 15px;
}

.invalid-field,
.invalid-field:hover {
  border: 1px solid red !important;
}

.invalid-field-message {
  color: red;
  font-size: 12px;
  margin-left: 5px;
}

.login-input-box::placeholder {
  color: var(--main-gray);
}

.login-input-box input::placeholder {
  font-weight: 500;
  color: var(--main-gray);
}

.login-input-phone-container {
  width: 100%;
}

.login-input-phone {
  margin-left: 10px;
  width: calc(100% - 90px - 10px);
}

.login-terms-container {
  margin-top: 25px;
  color: var(--main-gray);
  font-size: 11px;
  line-height: 14px;
  height: 14px;
  display: flex;
  align-items: center;
}

.login-terms-text {
  margin-left: 5px;
}

.ant-checkbox-wrapper {
  height: 32px;
}

.ant-checkbox .ant-checkbox-inner {
  width: 14px;
  height: 14px;
}

.terms-checkbox-error .ant-checkbox .ant-checkbox-inner {
  border-color: red !important;
}

.terms-text-error {
  color: red;
}

.login-submit-button-container {
  margin: 35px 0 50px 0;
}

.login-submit-button-container button:hover {
  background-color: var(--dark-yellow);
  transform: scale(1.02);
}

.login-provider-separator-container {
  height: 1px;
  background-color: var(--light-border);
  display: flex;
  justify-content: center;
  margin: 60px 0 34px 0;
}

.login-provider-separator-text {
  height: 17px;
  width: 90px;
  margin-top: -10px;
  background-color: #FFFFFF;
  text-align: center;
  color: var(--secondary-gray);
  font-size: 12px;
}

.signup-otp-label {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.otp-container {
  display: flex;
  justify-content: space-between;
  max-width: 350px;
}

.otp-input {
  border: 1px solid var(--main-yellow);
  width: 45px !important;
  height: 52px;
  background-color: var(--secondary-yellow);
  border-radius: 5px;
  font-weight: 500;
  font-size: 24px;
  color: var(--main-yellow);
  outline-color: var(--main-yellow);
  padding-top: 3px;
}

.otp-complete {
  border: 1px solid var(--main-green-correct);
  color: var(--main-green-correct);
  background-color: var(--secondary-green-correct);
  outline-color: var(--main-green-correct);
}

.otp-error {
  border: 1px solid var(--main-red-error);
  color: var(--main-red-error);
  background-color: var(--secondary-red-error);
  outline-color: var(--main-red-error);
}

.login-input-country-select {
  margin-top: 30px;
}

.login-input-country-select-disabled button {
  border: none;
  background-color: transparent;
  padding-left: 0;
  color: var(--main-black);
}

.login-input-country-select-disabled button span:first-child {
  padding-left: 0;
}

.login-input-country-select-disabled button:after {
  content: none !important;
}

.login-input-country-select button {
  border: 1px solid var(--light-border);
}

.login-input-country-select ul {
  width: 100%;
  border: 1px solid var(--light-border);
}

.login-input-country-select ul div input {
  border: 1px solid var(--light-border);
}

.login-access-type-menu-container li:hover::after {
  border-bottom: 2px solid var(--main-yellow) !important;
}

@media only screen and (min-width: 992px) {
  .b2b-login-screen-container {
    margin-top: 15vh;
  }

  .login-title {
    font-size: 26px !important;
  }

  .login-providers-buttons-responsive-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
  }

  .otp-container {
    max-width: 420px;
  }

  .otp-input {
    width: 64px !important;
    height: 69px;
    font-size: 30px;
  }
}