.profile-promotions-ticket-container {
  position: relative;
  margin-bottom: 35px;
  max-width: 290px;
  width: 100%;
}

.profile-promotions-ticket-container .ant-image {
  position: absolute;
  top: 0;
  right: 0;
}

.profile-promotions-ticket-available-text {
  font-size: 12px;
  color: #FFFFFF;
  position: absolute;
  top: 14px;
  right: 20px;
}

.profile-promotions-ticket-discount {
  font-size: 36px;
  font-weight: 700;
  display: flex;
  color: var(--main-black);
  position: relative;
  line-height: 50px;
}

.profile-promotions-ticket-title {
  font-size: 18px;
  font-weight: 700;
  display: flex;
  color: var(--main-black);
  position: relative;
}

.profile-promotions-ticket-description {
  font-size: 12px;
  display: flex;
  color: #FFFFFF;
  position: relative;
  line-height: 16px;
}

.profile-promotions-ticket-expired {
  color: var(--main-gray);
}

.profile-promotions-container {
  display: flex;
  flex-wrap: wrap;
  max-width: 730px;
}

.profile-promotions-expired-container {
  padding-top: 22px;
  border-top: 1px solid #D1D1D1;
}

.profile-promotions-ticket-text-container {
  padding: 10px 25px;
}

.profile-promotions-container>div {
 width: 100%;
}

@media only screen and (min-width: 480px) {
  .profile-promotions-ticket-container {
    margin-right: 20px;
    max-width: 341px;
  }

  .profile-promotions-ticket-text-container {
    padding: 20px 30px;
  }

  .profile-promotions-container>div {
    margin-right: 20px;
    width: 341px;
  }
}