.footer-container {
  background-color: var(--main-black);
}

.ant-layout-footer {
  padding: 0;
}

.footer-description-container {
  padding: 30px 20px;
  max-width: 335px;
}

.footer-description-logo .ant-image {
  min-width: 125px;
}

.footer-description-text {
  color: #FFFFFF;
  font-size: 12px;
}

.footer-description-text-container {
  margin: 20px 0 30px 0;
}

.footer-description-investor-relations {
  color: var(--main-yellow) !important;
  font-weight: 500;
  font-size: 15px;
  cursor: pointer;
}

.footer-separator-line {
  height: 1px;
  background-color: #FFFFFF;
  margin: 18px 20px;
  width: 100%;
  max-width: 1230px;
}

.footer-reserved-rights-text {
  color: #D1D1D1;
  margin: 0 20px;
  font-size: 12px;
}

.footer-buttons-container-container {
  display: flex;
  flex-direction: column;
}

.footer-policy-button {
  font-size: 12px;
  color: var(--main-yellow) !important;
  margin: 15px 0;
  width: 100px;
  line-height: 10px;
  text-align: end;
}

.footer-terms-buttons-container {
  padding: 0 20px;
}

.footer-terms-button {
  font-size: 12px;
  color: var(--main-yellow) !important;
}

.footer-policy-button:hover {
  color: var(--dark-yellow) !important;
}

.footer-policy-button-container .footer-policy-button:first-child {
  border-right: 1px solid #D1D1D1;
  margin-left: 20px;
  text-align: left;
}

.footer-bottom-buttons-container {
  padding: 50px 20px 130px 20px;
  display: flex;
  justify-content: space-between;
}

.footer-bottom-buttons-lang {
  cursor: pointer;
}

.footer-bottom-buttons-lang:hover span {
  color: #FFFFFF !important;
}

.footer-bottom-buttons-lang span {
  color: #D1D1D1 !important;
  font-size: 12px;
  line-height: 16px;
}

.footer-bottom-buttons-lang-text {
  margin: 0 25px 0 8px;
}

.footer-bottom-buttons-store {
  width: 70px;
  display: flex;
  justify-content: space-between;
  margin-top: -8px;
}

.footer-bottom-buttons-store .ant-image:hover {
  cursor: pointer;
  filter: brightness(1.1);
}

@media only screen and (min-width: 568px) {
  .footer-buttons-container-container {
    display: flex;
    flex-direction: row;
  }

  .footer-policy-button-container .footer-policy-button:nth-child(2) {
    border-right: 1px solid #D1D1D1;
    margin-left: 20px;
    text-align: left;
  }

  .footer-terms-buttons-container {
    display: flex;
    align-items: center;
  }
}

@media only screen and (min-width: 768px) {
  .footer-container {
    padding: 0 50px;
  }

  .footer-cards-container {
    display: flex;
    justify-content: space-between;
  }

  .footer-description-container {
    max-width: 310px;
  }

  .footer-description-logo .ant-image {
    min-width: 170px;
  }
}

@media only screen and (min-width: 1024px) {
  .footer-container {
    padding: 0 79px;
  }

  .footer-description-cards-container {
    display: flex;
    max-width: 1270px;
    margin: 0 auto;
    justify-content: space-between;
  }

  .footer-cards-container {
    display: flex;
    justify-content: space-around;
  }

  .footer-cards-container {
    width: calc(100% - 310px);
  }

  .footer-bottom-container {
    display: flex;
    max-width: 1270px;
    justify-content: space-between;
    margin: 0 auto;
  }

  .footer-policy-button {
    margin: 4px 0;
    height: 12px;
    width: 94px;
  }

  .footer-policy-button:last-of-type {
    margin-right: 20px;
  }

  .footer-bottom-buttons-container {
    padding: 0 20px;
    margin: 0 0 130px 0;
    width: 310px;
  }

  .footer-buttons-container-container {
    flex-direction: column;
  }
}

@media only screen and (min-width: 1300px) {
  .footer-buttons-container-container {
    flex-direction: row;
  }

  .footer-terms-buttons-container {
    padding: 0;
    align-items: baseline;
  }
}