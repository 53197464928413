.profile-order-all-filters-container {
  display: flex;
  justify-content: space-between;
  padding: 0 5px;
  border-bottom: 1px solid #eaeaea;
  box-sizing: border-box;
  height: 25px;
  margin-bottom: 20px;
}

.profile-order-range-picker-container {
  position: relative;
  height: 25px;
  width: 50px;
}

.profile-order-range-picker-container:hover {
  border-bottom: 1px solid var(--main-yellow);
}

.profile-order-range-picker-container:hover > .profile-order-rage-picker-text {
  color: var(--main-black);
}

.profile-order-filter-container .ant-image,
.profile-order-range-picker-container .ant-image {
  position: absolute;
  left: 0;
  top: 5px;
  min-width: 9px;
  filter: grayscale(1);
}

.profile-order-filter-container:hover > .ant-image,
.profile-order-range-picker-container:hover > .ant-image {
  filter: grayscale(0) !important;
}

.profile-order-range-picker {
  border: none !important;
  width: 100%;
  height: 25px;
  cursor: pointer;
  z-index: 1;
  background-color: transparent;
}

.profile-order-range-picker .anticon-calendar,
.profile-order-range-picker div,
.profile-order-range-picker svg {
  display: none;
}

.profile-order-rage-picker-text {
  height: 20px;
  width: 100%;
  position: absolute;
  left: 0;
  font-size: 13px;
  font-weight: 500;
  padding-left: 9px;
  padding-bottom: 5px;
  color: var(--secondary-gray);
}

.profile-order-filter-container {
  height: 25px;
  cursor: pointer;
  padding-bottom: 5px;
}

.profile-order-filter-container:hover {
  border-bottom: 1px solid var(--main-yellow);
}

.profile-order-filter-container:hover > .profile-order-filter-text {
  color: var(--main-black);
}

.profile-order-filter-text {
  font-size: 13px;
  font-weight: 500;
  padding-left: 15px;
  color: var(--secondary-gray);
}

.ant-checkbox-checked .ant-checkbox-inner {
  background: #fff;
  border-color: var(--main-yellow);
}

.ant-checkbox-wrapper:hover .ant-checkbox-checked::after,
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: var(--main-yellow);
}

.ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: var(--main-black);
}
