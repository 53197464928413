.go-back-button-container {
  margin: 45px 0 35px 20px;
}

.go-back-button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  padding: 5px 3px 0 0;
  box-shadow: 0px 4px 25px 0px #00000014;
  border: none;
}

.go-back-button:hover,
.go-back-button:focus,
.go-back-button:active {
  border: none !important;
  background-color: #FFFFFF !important;
  box-shadow: 0px 4px 25px 0px #00000014 !important;
}

.go-back-button-text {
  font-size: 12px;
  margin-left: 10px;
}

@media only screen and (min-width: 768px) {
  .go-back-button-container {
    display: none;
  }
}