.checkout-header-container {
  width: 100%;
  background-color: var(--main-black);
  display: flex;
  flex-direction: column;
}

.faq-header-container {
  background-color: #FFFFFF !important;
  height: 120px !important;
}

.checkout-header-progress-bar-container {
  width: 100%;
  height: 5px;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  margin: 25px 0 20px 0;
}

.checkout-header-bar-progress-bar {
  width: 31.5%;
  height: 5px;
  background-color: #606060;
  border: none;
}

.checkout-header-active-bar-progress-bar {
  background: linear-gradient(90deg, #FFC670 0%, #FFD16C 100%);
}

.checkout-header-label {
  color: #FFFFFF;
  font-weight: 700;
  font-size: 15px;
  margin-left: 20px;
}

.checkout-header-description {
  font-size: 14px;
  color: var(--main-gray);
  padding: 0 80px;
  line-height: 30px;
  display: none;
  margin-top: -10px;
}

.checkout-header-desktop-container {
  display: none;
}

.checkout-header-content-container {
  width: 100%;
  max-width: 1390px;
  margin: -15px auto 0;
}

@media only screen and (min-width: 768px) {

  .checkout-header-desktop-container,
  .checkout-header-description {
    display: block;
  }

  .checkout-header-label {
    color: var(--main-black);
    font-size: 36px;
    padding: 0 60px;
  }

  .checkout-header-progress-bar-container {
    padding: 0 80px;
  }

  .checkout-header-container {
    background-color: #FFFFFF;
  }
}

@media only screen and (min-width: 980px) {

  .checkout-header-progress-bar-container,
  .checkout-header-description {
    padding: 0 125px;
  }

  .checkout-header-label {
    padding: 0 105px;
  }
}