.search-vip-lounge-container {
  width: 100%;
  max-width: 1170px;
  margin: 50px auto 0;
}

.search-vip-lounge-title {
  font-size: 22px;
  font-weight: 700;
  margin: 30px 0;
}

.search-vip-lounge-input-container {
  width: 100%;
  max-width: 413px;
  border-radius: 100px;
  height: 76px;
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  box-shadow: 0px 10px 40px 0px #0000000D;
}

.search-vip-lounge-input-container .button-search-flight-style {
  position: relative;
  top: 0;
  right: 0;
  width: 57px;
  height: 57px;
  margin: 0;
  padding-top: 10px;
}

.search-vip-lounge-input-container .home-flight-search-input-container {
  width: calc(100% - 70px);
}

.search-vip-lounge-input-container .home-flight-search-input-icon .ant-image {
  top: 21px;
  left: 10px;
}

.search-vip-lounge-input-container .home-flight-search-input {
  border: none;
  padding: 3px 0 0 35px;
  margin: 0;
  height: 100%;
}

.search-vip-lounge-cards-container {
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  margin-top: 40px;
  width: 100vw;
  margin-left: -25px;
  position: relative;
  min-height: 50px;
  padding-left: 25px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.search-vip-lounge-cards-container::-webkit-scrollbar {
  display: none;
}

.search-vip-lounge-loader-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
}

.search-vip-lounge-no-results-text {
  padding: 0 30px;
  text-align: center;
  font-size: 15px;
  font-weight: 500;
}

@media only screen and (min-width: 768px) {
  .search-vip-lounge-container {
    padding-left: 55px;
  }

  .search-vip-lounge-input-container {
    height: 89px;
  }

  .search-vip-lounge-input-container .button-search-flight-style {
    width: 70px;
    height: 70px;
  }

  .search-vip-lounge-input-container .home-flight-search-input-icon .ant-image {
    left: -70px;
  }

  .search-vip-lounge-input-container .home-flight-search-input {
    padding: 10px 0 0 35px;
  }

  .search-vip-lounge-input-container .home-flight-search-input-container .home-flight-search-input {
    font-size: 21px;
  }

  .search-vip-lounge-cards-container {
    margin-left: -80px;
    padding-left: 80px;
  }
}

@media only screen and (min-width: 1256px) {
  .search-vip-lounge-container {
    padding-left: 0px;
  }
}