.home-header-profile-dropdown-icons-container {
  background-color: #FFFFFF;
  height: 30px;
  width: 55px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 11px 0 5px;
  cursor: pointer;
}

.home-header-profile-dropdown-menu-container {
  border-radius: 8px;
  box-shadow: 0px 4px 30px 0px #0000001F;
  padding: 20px 0 5px 0;
  margin-top: 8px;
  width: 172px;
  position: absolute;
  top: 0;
  right: 0;
}

.home-header-profile-dropdown-menu-container  li{
  padding-left: 20px;
  padding-right: 20px;
  font-size: 12px;
}

.home-header-profile-dropdown-menu-container  li:hover{
  background-color: #EAEAEA;
  font-size: 13px;
  font-weight: 500;
}