.b2b-payment-method-container {
  width: 100%;
  max-width: 430px;
  height: 100px;
  border-radius: 15px;
  box-shadow: 0px 4px 30px 0px #2727271c;
  margin: 20px auto 0;
  cursor: pointer;
}

.b2b-payment-method-container:hover,
.profile-add-payment-method-container:hover {
  transform: scale(1.01);
}

.b2b-payment-method-card-button-container,
.b2b-payment-method-card-logo-container {
  height: 100%;
  width: 70px;
  padding: 0 5px;
}

.b2b-payment-method-card-data-container {
  height: 100%;
  width: calc(100% - 140px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 10px;
}

.b2b-payment-method-card-data-container span:first-child {
  font-size: 18px;
  font-weight: 600;
}

.b2b-payment-method-card-data-container span:nth-child(2) {
  font-size: 14px;
  font-weight: 500;
  color: var(--secondary-gray);
}

.b2b-payment-confirm-button-container {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.profile-payment-methods-container .b2b-payment-method-container {
  margin: 20px 0;
}

.profile-payment-methods-title {
  font-size: 12px;
  color: var(--secondary-gray);
}

.profile-add-payment-method-container {
  width: 100%;
  max-width: 430px;
  height: 100px;
  border-radius: 15px;
  box-shadow: 0px 4px 30px 0px #2727271c;
  margin: 20px 0;
  cursor: pointer;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.profile-add-payment-method-container>span {
  font-size: 18px;
  font-weight: 500;
  color: var(--secondary-gray);
  max-width: calc(100% - 55px);
}

.b2b-payment-no-available-methods {
  margin-top: 50px;
  width: 100%;
  padding: 0 55px;
  color: var(--main-gray);
  font-size: 14px;
}