.error-404-content-container {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  text-align: center;
  padding: 40px 20px 100px;
}

.error-404-content-text-container {
  margin-top: 30px;
}

.error-404-content-text-container h1 {
  font-size: 26px;
  font-weight: 700;
}

.error-404-content-text-container span {
  font-size: 14px;
  font-weight: 500;
}

.error-404-content-text-container span span {
  color: var(--main-yellow);
}

@media only screen and (min-width: 768px) {
  .error-404-content-container {
    text-align: left;
    display: flex;
    max-width: none;
    padding-bottom: 0;
    padding-top: 0;
    justify-content: center;
    margin-top: 100px;
  }

  .error-404-content-text-container {
    max-width: 400px;
    margin-top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px 50px 0 0;
  }

  .error-404-content-text-container h1 {
    font-size: 30px;
  }

  .error-404-content-text-container span {
    font-size: 15x;
  }

  .error-404-content-container .ant-image {
    max-width: 450px;
  }
}

@media only screen and (min-width: 1440px) {
  .error-404-content-text-container h1 {
    font-size: 36px;
  }

  .error-404-content-text-container {
    padding-right: 0px;
    max-width: 520px;
  }

  .error-404-content-container .ant-image {
    max-width: 600px;
  }
}