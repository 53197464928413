.footer-card-container {
  padding: 15px 20px;
}

.footer-card-title {
  color: #FFFFFF !important;
  font-size: 15px !important;
  font-weight: 500 !important;
}

.footer-card-title-line {
  width: 12px;
  height: 1.5px;
  background-color: var(--main-yellow);
  margin: 5px 0;
}

.footer-card-content {
  color: #D1D1D1;
  font-size: 12px;
  line-height: 17px;
  margin: 6px 0;
  cursor: pointer;
}

.footer-card-content:hover {
  color: #FFFFFF;
}

.footer-card-image {
  margin: 10px 0;
  width: 20px;
}

@media only screen and (min-width: 768px) {
  .footer-card-container {
    width: 50%;
  }
}

@media only screen and (min-width: 1024px) {
  .footer-card-container {
    padding: 45px 20px;
  }
}