.home-header-flight-search-container {
  position: relative;
  margin: 0 auto;
  max-width: 970px;
  width: 90%;
}

.home-header-flight-search-select {
  font-size: 15px;
  font-weight: 500;
  background-color: transparent !important;
  color: #FFFFFF;
  letter-spacing: .5px;
  margin-left: 30px;
}

.home-header-flight-search-select .ant-select-selector {
  border: none !important;
  background-color: transparent !important;
}

.home-header-flight-search-inputs-container {
  background-color: #FFFFFF;
  border-radius: 100px;
  height: 94px;
  padding-left: 10px;
}

.home-header-flight-search-input-swipe-button {
  width: 32px;
  height: 32px;
  border-radius: 8px;
  background-color: #EAEAEA;
  position: absolute;
  top: 81px;
  left: 25%;
  padding: 0px;
  transform: rotate(90deg);
  border: none;
}

.home-header-flight-search-input-swipe-button:hover,
.home-header-flight-search-input-swipe-button:focus {
  transform: rotate(90deg) scale(1.05);
  background-color: #EAEAEA;
  border: none !important;
}

.home-header-flight-search-input-swipe-button .home-flight-search-switch-arrows {
  margin-top: 5px;
  width: 15px;
}

.home-flight-date-input-container {
  position: relative;
}

.home-header-flight-search-input-vertical-line {
  width: 1px;
  height: 80%;
  background-color: #D1D1D1;
  margin: 10px 20px 0 -30px;
}

.home-header-flight-search-input-result-list {
  width: 100%;
  background-color: #FFFFFF;
  border-radius: 40px;
  top: 10px;
  z-index: 10;
  padding: 15px 30px;
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.12);
  max-height: 390px;
  overflow-y: scroll;
}

.home-header-flight-search-input-result-list::-webkit-scrollbar {
  display: none;
}

.home-header-flight-result-available-services-text {
  position: absolute;
  top: 0;
  right: 30px;
  font-size: 12px;
  color: var(--secondary-gray);
}

.home-header-flight-search-spin-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  padding-top: 5px;
}

@media only screen and (max-width: 767.9px) {
  .home-header-flight-search-container {
    display: none;
  }
}

@media only screen and (min-width: 1024px) {
  .home-header-flight-search-container {
    margin-top: 40px;
  }

  .home-header-flight-search-inputs-container {
    margin-bottom: 100px;
  }

  .home-header-flight-search-inputs-container-results {
    margin-bottom: 0;
  }

  .home-header-flight-search-input-result-list {
    padding: 16px 60px;
  }

  .home-header-flight-result-available-services-text {
    right: 60px;
  }

  .home-header-flight-search-input-swipe-button {
    left: 27%;
  }
}