.ant-layout-sider {
    height: 100vh;
    min-height: 725px;
}

.ant-layout-sider-children {
    height: 100%;
}

.ant-layout-sider-children div {
    height: 100%;
}

@media only screen and (max-width: 991px) {
    .ant-layout-sider {
        display: none;
    }
}