.header-flights-list-item-container {
  height: 90px;
  border-bottom: 1px dashed var(--secondary-gray);
  cursor: pointer;
}

.header-flights-list-item-container:hover {
 background-color: rgba(128, 128, 128, 0.05);
}

.header-flights-list-item-container .ant-col {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.header-flights-list-airline-logo {
  width: 55px;
  height: 20px;
  margin-top: 35px;
  object-fit: contain;
}

.header-flights-list-item-container .ant-col:nth-child(2),
.header-flights-list-item-container .ant-col:nth-child(4) {
  width: 65px;
  padding-left: 10px;
}

.header-flights-list-item-container .ant-col:nth-child(5),
.header-flights-list-item-container .ant-col:nth-child(6) {
  width: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header-flights-list-item-container .ant-col:nth-child(5) {
  height: 38px;
  margin-top: 26px;
  border-left: 1px solid #D1D1D1;
}

.header-flights-list-item-container .ant-col:nth-child(7) {
  width: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 38px;
  margin-top: 26px;
  border-left: 1px solid #D1D1D1;
}

.header-flights-list-item-container .ant-col:nth-child(7) div:first-child {
  color: var(--main-gray) !important;
}

.header-flights-list-item-container .ant-col div {
  line-height: 20px;
  font-size: 12px;
  color: var(--secondary-gray);
}

.header-flights-list-item-container .ant-col div:first-child {
  font-weight: 500;
  font-size: 15px;
  color: var(--main-black);
}

.header-flights-list-item-container .flights-list-item-time-travel-text-container {
  margin: 0 !important;
  width: 100px;
}

.header-flights-list-item-container .flights-list-item-time-travel-text-container span {
  line-height: 35px !important;
  height: 25px;
}

.flights-list-item-available-services-container {
  width: calc(100% - 525px);
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.flights-list-item-available-services-service {
  border: 1px solid #FCB400;
  background-color: var(--secondary-yellow);
  width: 29px;
  height: 29px;
  border-radius: 50%;
  margin-left: 8px;
}

.flights-list-item-available-services-service .ant-image {
  width: 44%;
  margin-left: 28%;
  filter: invert(1);
  display: flex;
  align-items: center;
  height: 100%;
}

.flights-list-item-available-services-service:first-child {
  margin-left: 0 !important;
}

@media only screen and (min-width: 1024px) {
  .flights-list-item-available-services-service {
    margin-left: 12px;
  }

  .header-flights-list-item-container .flights-list-item-time-travel-text-container {
    width: 110px;
  }

  .flights-list-item-available-services-container {
    width: calc(100% - 675px);
  }

  .header-flights-list-item-container .ant-col:nth-child(2),
  .header-flights-list-item-container .ant-col:nth-child(4) {
    width: 85px;
    padding-left: 20px;
  }

  .header-flights-list-item-container .ant-col:nth-child(5),
  .header-flights-list-item-container .ant-col:nth-child(6) {
    width: 105px;
  }

  .header-flights-list-item-container .ant-col:nth-child(7) {
    width: 130px;
  }
}