.profile-header-navbar-container {
  display: flex;
  justify-content: space-between;
  padding: 0 25px;
}

.profile-header-navbar-container div {
  /* cursor: pointer; */
  font-size: 15px;
  font-weight: 500;
  height: 43px;
}

.profile-header-navbar-text-active {
  border-bottom: 1px solid var(--main-yellow);
  cursor: pointer;
}

.profile-header-navbar-photo-container,
.profile-header-navbar-logout-text {
  display: none;
  cursor: default !important
}

@media only screen and (min-width: 768px) {
  .profile-header-navbar-container {
    width: 250px;
    height: 646px;
    border-radius: 15px;
    background-color: #EAEAEA;
    flex-direction: column;
    position: absolute;
    z-index: 10;
    top: 150px;
    left: 50px;
    padding: 20px;
  }

  .profile-header-navbar-container div {
    font-weight: 700;
    height: 80px;
    width: 270px;
    display: flex;
    align-items: center;
  }

  .profile-header-navbar-text-active {
    margin-left: -30px;
    background-color: var(--main-yellow);
    border-radius: 15px;
    padding: 0 30px;
  }

  .profile-header-navbar-photo-container {
    display: none;
    flex-direction: column;
    height: 250px !important;
  }

  .profile-header-navbar-photo-description-text {
    display: flex;
    flex-direction: column;
    margin-top: 8px;
  }

  .profile-header-navbar-photo-description-text span {
    font-size: 9px;
    font-weight: 400;
    color: var(--main-gray);
    line-height: 20px;
    text-align: center;
  }

  .profile-header-navbar-photo-description-text span:first-child {
    text-decoration: underline;
  }

  .profile-header-navbar-photo-frame {
    display: flex;
    justify-content: center;
  }

  .profile-header-navbar-photo-frame img {
    border-radius: 50%;
    width: 102px !important;
    height: 102px !important;
    object-fit: cover;
  }

  .profile-header-navbar-photo-frame div:first-child {
    width: 110px !important;
    height: 110px !important;
    border-radius: 50% !important;
    border: 4px solid #FFFFFF !important;
    position: relative;

  }

  .profile-header-navbar-photo-edit {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    color: #FFFFFF;
    background-color: var(--dark-yellow);
    border: none;
    padding: 0;
    position: absolute;
    bottom: -13px;
    left: 41px;
  }

  .profile-header-navbar-photo-edit:hover {
    border: none;
  }

  .profile-header-navbar-logout-text {
    display: block;
    font-size: 12px !important;
    font-weight: 400 !important;
    text-decoration: underline;
    color: var(--main-gray);
    height: 30px !important;
    margin-top: 30px;
    cursor: pointer !important;
  }
}

@media only screen and (min-width: 1180px) {
  .profile-header-navbar-container {
    left: calc(50% - 540px);
  }
}

@media only screen and (min-width: 1440px) {
  .profile-header-navbar-container {
    left: calc(50% - 600px);
  }
}