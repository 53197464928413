.home-service-card-container {
  margin: 40px 0;
}

.home-service-card-image-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  max-width: 450px;
}

.home-service-card-image-mark-light {
  width: calc(100% - 70px);
  height: 198px;
  border-radius: 15px;
  background-color: #d1d1d181;
  position: absolute;
  top: -15px;
}

.home-service-card-image-mark-dark {
  width: calc(100% - 32px);
  height: 228px;
  border-radius: 15px;
  background-color: #D1D1D1;
  position: absolute;
  top: -7px;
}

.home-service-card-image {
  height: 280px;
  width: 100%;
  border-radius: 15px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: 1;
}

.home-service-title {
  font-size: 24px !important;
  font-weight: bold !important;
  margin: 20px 0 30px 0 !important;
}

.home-service-description {
  font-size: 12px;
  white-space: pre-wrap;
}

@media only screen and (min-width: 768px) {
  .home-service-card-container {
    display: flex;
    width: 100%;
    max-width: 1170px;
    justify-content: space-between;
  }

  .card-reverse {
    flex-direction: row-reverse;
  }

  .home-service-text-container {
    width: 470px;
    height: 370px;
    top: -50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 0 0 20px;
  }

  .card-reverse .home-service-text-container {
    padding: 0 20px 0 0;
  }
}

@media only screen and (min-width: 1024px) {
  .home-service-card-image-container {
    min-width: 500px;
  }

  .home-service-card-container {
    margin: 40px auto;
  }

  .home-service-card-image {
    height: 350px;
  }

  .home-service-text-container {
    height: 470px;
  }

  .home-service-title {
    font-size: 30px !important;
  }

  .home-service-description {
    font-size: 14px;
  }
}

@media only screen and (min-width: 1256px) {
  .home-service-card-image-container {
    min-width: 570px;
  }

  .home-service-card-image {
    height: 396px;
  }
}