.home-flight-search-container {
  margin-bottom: 50px;
  position: relative;
}

.home-flight-search-select {
  width: 90%;
  margin: 50px 5% 10px 5%;
  font-size: 15px;
  font-weight: 500;
}

.home-flight-search-select div {
  border: none !important;
}

.home-flight-search-input-swipe-button {
  border: 1px solid #EAEAEA;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  position: absolute;
  top: 150px;
  right: 35px;
  padding: 0;
}

.home-flight-search-input-swipe-button:hover {
  transform: scale(1.05) !important;
  border: 1px solid #EAEAEA;
  background-color: #FFFFFF;
}

.home-flight-search-input-swipe-button:focus {
  border: 1px solid #EAEAEA;
  background-color: #FFFFFF;
}

.home-flight-search-switch-arrows {
  margin-top: 7px;
}

.home-flight-search-input .ant-picker-input {
  flex-direction: row-reverse;
}

.home-flight-search-input .ant-picker-input .ant-picker-suffix {
  margin: 0 17px 0 0;
  color: var(--main-black);
}

.home-flight-search-input .ant-picker-input input {
  padding-top: 4px;
  font-size: 17px;
  font-weight: 700;
  color: var(--main-black);
}

.home-flight-date-input-container {
  position: relative;
}

.home-flight-error-message {
  font-size: 12px;
  font-weight: 500;
  color: red;
  padding-left: 10px;
}

@media only screen and (min-width: 768px) {
  .home-flight-search-container {
    display: none;
  }

  .home-flight-error-message {
    position: absolute;
    padding-left: 30px;
    font-size: 14px;
    letter-spacing: .5px;
    bottom: -40px;
  }
}

@media only screen and (min-width: 1024px) {
  .home-flight-search-input .ant-picker-input input {
    font-size: 22px;
  }
}