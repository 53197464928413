.flights-list-item-container {
  width: calc(100% - 40px);
  height: 167px;
  margin: 20px auto;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 5px 60px 0px #0000000D;
  cursor: pointer;
  max-width: 400px;
}

.flights-list-item-container:hover {
  transform: scale(1.02);
}

.flights-list-item-time-travel-container {
  display: flex;
  justify-content: center;
  margin: 17px 0;
}

.flights-list-item-time-travel-text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.flights-list-item-time-travel-text-container:nth-child(2) {
  margin: 20px 15px 0 15px;
}

.flights-list-item-time-travel-text-container .ant-image {
  margin-bottom: 5px;
}

.flights-list-item-travel-date {
  font-size: 10px;
  color: #D1D1D1;
}

.flights-list-item-time-travel-hours {
  font-size: 15px;
  font-weight: bold;
}

.flights-list-item-time-travel-airport {
  font-size: 12px;
  color: var(--secondary-gray);
  text-transform: uppercase;
}

.flights-list-item-airline-container {
  width: 310px;
  margin-top: 7px;
}

.flights-list-item-detail-travel-text-container {
  display: flex;
  justify-content: space-between;
  padding: 0 8px;
}

.flights-list-item-detail-travel-text-container span {
  font-size: 12px;
  color: var(--secondary-gray);
  min-width: 45px;
  text-align: center;
}

.flights-list-item-airline-text-container {
  margin-top: 12px;
}

.flights-list-item-airline-text-container span {
  color: var(--main-black);
  font-size: 12px;
}

.flights-list-item-airline-text-container .ant-image {
  width: 70px;
}