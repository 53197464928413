.vip-input-profile-icon-container {
  position: relative;
  width: 35px;
  height: 35px;
  padding-top: 2px;
}

.vip-input-profile-icon-container .ant-image {
  width: 22px;
}

.vip-input-profile-id {
  position: absolute;
  top: 0;
  right: 0;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: var(--main-black);
  color: #FFFFFF;
  font-size: 9px;
  padding-top: 1px;
}

.vip-input-profile-fields-container {
  width: calc(100% - 40px);
  position: relative;
}

.vip-input-profile-fields-container input {
 background-color: transparent;
 border: none;
 border-bottom: 1px dashed var(--main-black) !important;
 font-size: 15px;
 font-weight: 500;
 padding: 0 4px !important;
 margin-bottom: 5px;
 color: var(--main-black) !important;
}

.vip-input-profile-fields-container input:hover,
.vip-input-profile-fields-container input:focus {
  border-bottom: 1px dashed var(--main-black) !important;
 }

.vip-input-profile-fields-container input::placeholder {
  color: #ECA201;
 }

 .vip-input-profile-remove-user-button {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: 1px solid var(--dark-yellow);
  padding: 0;
  position: absolute;
  right: 0;
  line-height: 14px;
  font-size: 11px;
  transform: rotateZ(45deg);
  background-color: transparent;
 }

 .vip-input-profile-remove-user-button:hover,
 .vip-input-profile-remove-user-button:focus,
 .vip-input-profile-remove-user-button:active {
  border: 1px solid var(--dark-yellow) !important;
  background-color: transparent !important;
  color: var(--main-black) !important;
 }

 .vip-input-profile-fields-date-picker {
   background-color: transparent;
   border: none;
   width: 100%;
   padding: 0;
 }

 .vip-input-profile-fields-date-picker:hover {
  border: none !important;
}

 .vip-input-profile-fields-date-picker .ant-picker-suffix {
  color: #ECA201;
  position: absolute;
  right: 5px;
  top: 5px;
}