.thank-you-page-header-container {
  display: none;
}

.thank-you-page-container {
  padding: 20px 20px 120px;
}

.thank-you-page-h-line {
  width: 100%;
  height: 1px;
  background-color: #D1D1D1;
}

.thank-you-page-header-title-container {
  margin: 50px 0;
}

.thank-you-page-header-title-container h1 {
  font-size: 22px;
  font-weight: 700;
  color: var(--main-black);
  letter-spacing: .5px;
  margin-bottom: 2px;
}

.thank-you-page-header-title-container span {
  font-size: 12px;
  color: var(--main-gray);
}

.thank-you-page-service-container {
  margin-bottom: 30px;
  height: 345px;
  position: relative;
}

.thank-you-page-service-container span {
  font-size: 18px;
  font-weight: 700;
  line-height: 40px;
}


@media only screen and (min-width: 768px) {
  .thank-you-page-header-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 110px;
  }

  .thank-you-page-container {
    padding: 20px 10vw;
  }

  .thank-you-page-header-title-container h1 {
    font-size: 36px;
  }

  .thank-you-page-header-title-container span {
    font-size: 14px;
  }

  .thank-you-page-service-container span {
    font-size: 26px;
  }
}