.checkout-service-card-container {
  border-radius: 15px;
  margin: 15px 0 25px;
  padding: 40px 20px 10px;
  position: relative;
  box-shadow: 0px 4px 30px 0px #0000001A;
  width: calc(100vw - 50px);
  max-width: 470px;
  overflow: hidden;
}

.checkout-service-card-badge {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  position: absolute;
  top: 15px;
  left: 15px;
  background-color: var(--secondary-yellow);
  display: flex;
  align-items: center;
}

.checkout-service-card-badge .ant-image {
  width: 100%;
}

.checkout-service-card-badge-image {
  width: 45%;
  margin: 0 auto;
}

.checkout-service-card-remove-text {
  font-size: 12px;
  color: var(--secondary-gray);
  position: absolute;
  top: 15px;
  right: 20px;
  cursor: pointer;
}

.checkout-service-card-footer-container {
  height: 55px;
  display: flex;
  justify-content: flex-end;
}

.checkout-service-card-footer-add-buttons-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 55px;
  margin-right: 20px;
}

.checkout-service-card-footer-add-buttons-container .ant-image {
  cursor: pointer;
}

.checkout-service-card-footer-confirm-button-container {
  display: flex;
  align-items: center;
}

.checkout-service-card-footer-confirm-button {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  border: 1px solid var(--main-yellow);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  padding-top: 5px;
  transition: 0s;
}

.checkout-service-card-footer-confirm-button:hover,
.checkout-service-card-footer-confirm-button:focus {
  background-color: transparent;
  border: 1px solid var(--main-yellow);
  color: var(--main-black);
}

.checkout-service-card-footer-confirm-button:hover .green-border,
.checkout-service-card-footer-confirm-button:active .green-border,
.green-border {
  border: 1px solid #68E097 !important;
  background-color: transparent !important;
}

.checkout-service-card-footer-numbers-container {
  width: 24px;
  height: 24px;
  border-radius: 5px;
  background-color: var(--secondary-yellow);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  padding-top: 2px;
}

.checkout-service-card-footer-no-description-container {
  position: absolute;
  right: 10px;
  bottom: 10px;
  width: 140px;
}

@media only screen and (min-width: 768px) {
  .checkout-service-card-container {
    width: 95%;
    margin: 20px 20px 20px 0;
  }

  .b2b-pre-checkout-cards-container>div>div {
    width: 370px;
  }

  .checkout-service-card-footer-container {
    justify-content: flex-end;
  }
}

@media only screen and (min-width: 1440px) {
  .checkout-services-page-container .checkout-service-card-container {
    max-width: 470px;
  }
}