.checkout-services-box-services-icons-container {
  margin-bottom: 20px;
}

.checkout-services-box-container {
  background-color: #FFD45C;
  width: 100%;
  max-width: 560px;
  border-radius: 25px;
  margin-bottom: 65px;
  padding: 30px 23px;
}

.checkout-services-box-horizontal-line {
  width: 100%;
  height: 1px;
  background-color: var(--main-black);
}

.checkout-services-box-services-icon {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: var(--main-black);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  margin-right: 14px;
}

.checkout-services-box-profile-icon-container {
  width: 34px;
  height: 34px;
  background-color: var(--main-black);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
}

.checkout-services-box-profile-user-container {
  display: flex;
  align-items: center;
  margin: 24px 0;
}

.checkout-services-box-profile-user-name-text {
  font-size: 15px;
  font-weight: 700;
  margin: 0px 10px;
}

.checkout-services-box-vip-container {
  border: 1px solid var(--main-black);
  border-radius: 15px;
  padding: 25px 15px;
  margin-bottom: 25px;
  margin-top: 35px;
}

.checkout-services-box-vip-preview-container {
  display: flex;
  align-items: center;
}

.checkout-services-box-arrow-container {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  padding-top: 2px;
}

.checkout-services-box-vip-service-text-container {
  display: flex;
  justify-content: space-between;
}

.checkout-services-box-vip-service-text-container .ant-image {
  width: 12px;
  margin-right: 5px;
  cursor: pointer;
}

.checkout-services-box-vip-service-text-container span {
  font-size: 12px;
  color: #ECA201;
  margin-bottom: 7px;
}

.checkout-services-box-vip-service-tot-text-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0 20px;
}

.checkout-services-box-vip-service-tot-text-container span {
  font-size: 12px;
  color: var(--main-black);
}

.checkout-services-box-vip-service-tot-text-container span:nth-child(2),
.checkout-services-box-vip-service-tot-text-container span:nth-child(2) span {
  font-size: 18px;
  font-weight: 700;
}

.checkout-services-box-profile-add-user {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  border: none;
  font-size: 30px;
  padding-left: 12px;
  padding-top: 0px;
  margin: 15px 0 5px;
  line-height: 46px;
}

.checkout-services-box-profile-add-user:hover,
.checkout-services-box-profile-add-user:focus,
.checkout-services-box-profile-add-user:active {
  background-color: #FFFFFF !important;
  color: var(--main-black) !important;
}

.checkout-services-box-complete-button-container {
  max-width: 370px;
  margin: 0 auto;
}

.checkout-services-box-container .ant-checkbox-wrapper {
  margin-top: 20px !important;
  font-size: 14px;
}

.checkout-services-box-missing-data-error-message-container {
  text-align: center;
  margin-top: 5px;
}

.checkout-services-box-missing-data-error-message {
  color: red;
  font-weight: 500;
}

.checkout-services-box-user-data-container {
  width: 100%;
  margin-top: 40px;
}

.checkout-services-box-user-data-container label {
  font-size: 13px;
  font-weight: 500;
}

.checkout-services-box-user-data-container input {
  height: 48px;
  border-radius: 5px;
  margin-top: 5px;
  border: none;
  font-size: 16px;
  font-weight: 700;
}

.checkout-services-box-user-data-container div:first-child input {
  margin-bottom: 20px;
}

.checkout-services-box-ticket-number-text-container {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 700;
  margin-top: 40px;
}

.checkout-services-box-ticket-number-text-container .ant-image {
  margin-left: 5px;
}

.checkout-services-box-ticket-number-data-container>input,
.checkout-services-box-ticket-number-data-container .ant-picker {
  border-radius: 0;
  border: none;
  border-bottom: 1px dashed black;
  width: 100%;
  height: 40px;
  background-color: transparent;
  display: flex;
  margin-top: 15px;
}

.checkout-services-box-ticket-number-data-container>input:hover,
.checkout-services-box-ticket-number-data-container>input:focus,
.checkout-services-box-ticket-number-data-container .ant-picker:hover {
  border: none !important;
  border-bottom: 1px dashed black !important;
}

.checkout-services-box-ticket-number-data-container>input {
  font-size: 19px;
  font-weight: 700;
  color: var(--main-black);
}

.checkout-services-box-ticket-number-data-container .ant-picker input {
  font-size: 18px;
  font-weight: 600;
  color: var(--main-black);
  padding-top: 3px;
}

.checkout-services-box-ticket-number-data-container .ant-picker-input {
  flex-direction: row-reverse;
}

.checkout-services-box-ticket-number-data-container .ant-picker-suffix {
  color: var(--main-black);
  margin-right: 10px;
}

.checkout-services-box-ticket-number-data-container .ant-picker-clear {
  display: none;
}

.checkout-services-box-ticket-number-data-container input::placeholder {
  color: var(--main-black);
}

.checkout-services-box-receipt-title {
  font-weight: 700;
  font-size: 15px;
  margin: 10px 0;
}

.checkout-services-box-discount {
  font-weight: 600;
  margin-right: 5px;
  text-decoration: line-through;
  text-decoration-color: red;
  display: flex;
  align-items: center;
}

.checkout-services-box-ticket-number-data-drtravel {
  font-size: 18px;
  font-weight: 700;
  display: flex;
  align-items: flex-end;
  padding-bottom: 3px;
}

@media only screen and (min-width: 768px) {

  .checkout-services-box-services-icon,
  .checkout-services-box-profile-icon-container,
  .checkout-services-box-arrow-container {
    width: 50px;
    height: 50px;
    padding: 0;
  }

  .checkout-services-box-services-icon .ant-image,
  .checkout-services-box-profile-icon-container .ant-image {
    width: 23px;
  }

  .checkout-services-box-arrow-container .ant-image {
    width: 18px;
  }

  .checkout-services-box-profile-user-name-text {
    font-size: 18px;
    margin: 0px 20px;
  }

  .checkout-services-box-vip-service-text-container span,
  .checkout-services-box-vip-service-tot-text-container span {
    font-size: 15px;
  }

  .checkout-services-box-vip-service-tot-text-container span:nth-child(2),
  .checkout-services-box-vip-service-tot-text-container span:nth-child(2) span {
    font-size: 23px;
  }

  .checkout-services-box-user-data-container,
  .checkout-services-box-ticket-number-data-container {
    display: flex;
    justify-content: space-between;
  }

  .checkout-services-box-user-data-container .ant-col,
  .checkout-services-box-ticket-number-data-container>input,
  .checkout-services-box-ticket-number-data-container .ant-picker {
    width: calc(50% - 8px);
  }
}