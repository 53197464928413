.forgot-psw-container {
  padding: 15px;
  max-width: 400px;
  margin: 0 auto;
}

.forgot-psw-container ul {
  margin-top: 10px;
}

.forgot-psw-title {
  font-size: 22px !important;
  font-weight: 700 !important;
  margin-bottom: 5px !important;
}

.forgot-psw-subtitle {
  font-size: 12px;
  color: var(--secondary-gray);
}

.forgot-password-submit-button-container {
  margin-top: 50px;
}

.reset-psw-input-container {
  margin-top: 50px;
}

.forgot-password-email-sent-text-container {
  height: 147px;
  display: flex;
  align-items: center;
}

@media only screen and (min-width: 768px) {
  .forgot-psw-container {
    max-width: 470px;
    margin-top: 50px;
  }

  .forgot-password-submit-button-container {
    margin-top: 100px;
  }
}