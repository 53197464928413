.home-service-carousel-title {
  font-size: 22px !important;
  font-weight: bold !important;
  color: var(--main-black) !important;
  margin: 60px 0 !important;
}

.home-service-carousel {
  display: flex;
  width: 100vw;
  max-width: 1400px;
  margin-left: -20px;
  overflow-x: scroll;
  height: 435px;
  padding: 15px 5px 0;
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.home-service-carousel-active {
  cursor: grabbing;
}

.home-service-carousel::-webkit-scrollbar {
  display: none;
}

@media only screen and (min-width: 1024px) {
  .home-service-carousel-title {
    font-size: 36px !important;
    padding-left: 25px;
  }

  .home-service-carousel {
    height: 535px;
    padding: 15px 0 0 10px;
    width: 100%;
    margin: 0;
  }

  .home-service-carousel-container {
    width: 100%;
    max-width: 1210px;
    margin: 0 auto;
  }
}