.footer-checkout-container {
  background-color: var(--main-black);
  width: calc(100% + 50px);
  max-width: 100vw !important;
  margin-left: -25px;
  padding: 35px 5px 70px;
}

.footer-checkout-card-container {
  max-width: 330px;
  min-width: 250px;
}

.footer-checkout-card-container .footer-card-container {
  width: 100%;
}

.footer-checkout-content-container {
  width: 100%;
  max-width: 1300px;
}

.footer-checkout-responsive-content-container {
  width: 100%;
  max-width: 1170px;
  margin: 0 auto;
}

@media only screen and (min-width: 768px) {
  .footer-checkout-container {
    display: flex;
    padding-left: 60px;
    padding-right: 60px;
  }

  .footer-checkout-content-container {
   display: flex;
   justify-content: space-between;
   max-width: 800px;
  }
}

@media only screen and (min-width: 980px) {
  .footer-checkout-container {
    padding-left: 105px;
    padding-right: 105px;
  }
}