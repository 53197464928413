.faq-layout-header-container {
  padding: 0 !important;
  height: auto;
}

.faqs-layout-content-container {
  background-color: #FFFFFF;
  padding-bottom: 120px;
}

.faq-layout-content-spin-container {
  /* border: 1px solid red; */
  height: 50vh;
  display: flex;
  flex-direction: column;
  padding: 0 30px;
  justify-content: space-between;
}