.checkout-layout-container {
  min-height: 100vh;
  background-color: #FFFFFF;
}

.checkout-layout-container .ant-layout-content {
  padding-bottom: 0 !important;
}

.checkout-layout-header-container {
  padding: 0 !important;
  height: auto;
}

@media only screen and (min-width: 768px) {
  .checkout-layout-header-container {
    padding: 110px 0 0 !important;
  }
}