.checkout-user-data-container {
  margin-bottom: 15px;
}

.checkout-user-data-input-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  padding: 0 5px;
}

.checkout-user-data-input-container label {
  font-size: 13px;
  font-weight: 500;
}

.checkout-user-data-input-container input {
  height: 48px;
  border-radius: 5px;
  border: none;
  font-size: 13px;
  font-weight: 500;
  color: var(--main-black);
}

.checkout-user-data-input-container .ant-input-disabled {
  background-color: #FFFFFF;
  color: var(--main-black);
  cursor: default;
}

.checkout-user-data-input-container input::placeholder {
  font-size: 12px;
  color: var(--secondary-gray);
}

.checkout-user-data-phone-container>input {
  width: calc(100% - 95px);
  margin-left: 5px;
}

.checkout-user-data-phone-container .prefix-phone-search {
  margin-top: 0;
  border: none;
}

.checkout-user-country-input-container>div>button {
  border: none;
  background-color: #FFFFFF;
  height: 48px;
  font-size: 13px !important;
  font-weight: 500 !important;
}

.checkout-user-country-input-container>div>ul {
  border: none;
  box-shadow: 0px 4px 30px 0px #0000001A;
  border-radius: 10px;
  width: 100%;
}

@media only screen and (min-width: 480px) {
  .checkout-user-data-phone-container>input {
    width: calc(100% - 100px);
    margin-left: 10px;
  }

  .checkout-user-data-responsive-input-container .checkout-user-data-input-container {
    width: 50%;
  }

  .checkout-user-data-input-container>label {
    margin-bottom: 5px;
  }

  .checkout-user-country-input-container>div {
    max-width: 200px;
  }
}