.newsletter-container {
  height: 370px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  position: relative;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
}

.newsletter-container-mask {
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(22, 22, 22, 0.2) 0%, rgba(22, 22, 22, 0.8) 100%);
  position: absolute;
}

.newsletter-background-text {
  font-size: 20vw;
  font-weight: bold;
  color: rgb(255, 255, 255, 0.2);
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1px;
  white-space: nowrap;
  text-transform: uppercase;
  z-index: 1;
  position: absolute;
}

.newsletter-title-text {
  font-size: 28px !important;
  font-weight: bold !important;
  text-align: center;
  color: #FFFFFF !important;
  z-index: 1;
  position: absolute;
  top: 50px;
  margin: 0 20px !important;
  line-height: 39px !important;
  max-width: 600px;
}

.newsletter-input-container {
  margin: 180px 0 20px 0;
  position: relative;
  width: 90%;
  max-width: 714px;
  z-index: 5;
}

.newsletter-input {
  color: #D1D1D1;
  height: 58px;
  border-radius: 60px;
  padding-left: 21px;
  background-color: rgba(255, 255, 255, 0.2);
  border: none;
  position: relative;
}

.newsletter-input-submit {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: absolute;
  right: 4px;
  top: 4px;
  background-color: var(--main-yellow);
  border: 1px solid var(--main-yellow);
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 5px 22px 0 17px;
}

.newsletter-input-submit:hover {
  background-color: var(--dark-yellow);
  border: 1px solid var(--dark-yellow);
}

.newsletter-input-submit-icon {
  width: 22px;
  margin-bottom: 5px;
}

.newsletter-terms-container {
  position: relative;
  width: 90%;
  max-width: 650px;
}

.newsletter-terms-text {
  font-size: 12px;
  color: #D1D1D1;
  margin: 2px 0 0 20px;
  position: absolute;
}

.newsletter-invalid-field-message {
  color: red;
  font-size: 12px;
  margin-left: 5px;
  position: absolute;
  top: 60px;
}

.newsletter-input-submit-text {
  color: #FFFFFF;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: .5px;
  display: none !important;
}

@media only screen and (min-width: 768px) {
  .newsletter-background-text {
    font-size: 154px;
  }

  .newsletter-title-text {
    font-size: 36px !important;
    margin-top: 20px !important;
  }

  .newsletter-input-submit {
    width: 164px;
    border-radius: 40px;
    padding: 0px 22px 0 22px;
  }

  .newsletter-input-submit .ant-image {
    padding-top: 5px;
  }

  .newsletter-input-submit-text {
    display: block !important;
  }
}