.profile-toggle-container {
  margin-bottom: 15px;
  width: 50%;
  max-width: 340px;
  padding-right: 15px;
}

.profile-toggle-title {
  font-size: 13px !important;
  margin: 15px 0;
}

.profile-toggle-subtitle {
  font-size: 12px;
  color: var(--secondary-gray);
}

.profile-toggle {
  background-color: #6C5410;
}

.profile-toggle-container .ant-switch-checked {
  background-color: var(--main-yellow) !important;
}