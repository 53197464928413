.b2b-footer-checkout-container-100 {
  width: 100%;
  height: 400px;
  background-color: var(--main-black);
  padding-top: 30px;
}

.b2b-footer-checkout-container {
  min-width: calc(100% + 50px);
  height: 400px;
  background-color: var(--main-black);
  margin-left: -25px;
  padding-top: 30px;
}

.b2b-footer-checkout-content-container {
  width: 90%;
  height: 80%;
  max-width: 1230px;
}

.b2b-footer-checkout-links-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 200px;
  margin-bottom: 30px;
}

.b2b-footer-checkout-content-container a:first-of-type {
  margin-bottom: 15px;
}

.b2b-footer-checkout-content-container a {
  margin: 4px 0;
}

.b2b-footer-checkout-content-container a span {
  color: #FFFFFF;
  font-size: 12px;
  letter-spacing: .5px;
}

.b2b-footer-checkout-content-container .footer-separator-line {
  margin: 18px 0;
}

.b2b-footer-checkout-rights-container {
  display: flex;
  justify-content: space-between;
}

.b2b-footer-checkout-rights-container .footer-reserved-rights-text {
  margin: 0 0 15px 0;
}