.error-page-content-container {
  width: 80%;
  height: 200px;
  background-color: #D1D1D1;
  border-radius: 15px;
  margin: 90px auto;
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center;
}

.error-page-content-text-container {
  margin: 0 auto;
  height: 90vh;
  max-height: 500px;
  max-width: 570px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.error-page-content-text-container h1 {
  font-size: 36px;
  font-weight: 700;
}

.error-page-image {
  position: absolute;
  top: -90px;
  width: 100%;
  max-width: 420px;
}

.error-page-content-text-report-container {
  margin-top: 15px;
}

.error-page-content-text-report-container span span {
  color: var(--main-yellow);
}

@media only screen and (min-width: 768px) {
  .error-page-content-container {
    height: 290px;
    width: 70%;
  }

  .error-page-content-text-container {
    max-height: 600px;
  }

  .error-page-image {
    max-width: 700px;
    width: 700px;
    top: -170px;
  }
}