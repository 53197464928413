.home-no-stress-container {
  width: 100%;
  height: 393px;
  position: relative;
  margin: 40px 0;
}

.home-no-stress-inner-container {
  width: calc(100% + 40px);
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  position: absolute;
  left: -20px;
}

.home-no-stress-title {
  color: white !important;
  font-size: 28px !important;
  font-weight: bold !important;
  text-align: center;
  margin-top: 72px;
  margin-bottom: 0 !important;
  line-height: 50px !important;
  position: relative;
  z-index: 1;
}

@media only screen and (min-width: 768px) {

  .home-no-stress-title {
      font-size: 36px !important;
  }
}

.home-no-stress-subtitle-container {
  width: 100%;
  text-align: center;
}

.home-no-stress-subtitle {
  color: white;
  text-align: center;
  position: relative;
  z-index: 1;
  font-weight: 500;
}

.home-no-stress-button-container {
  width: 100%;
  text-align: center;
  margin: 50px 0 0;
  z-index: 1;
}

.home-no-stress-image-mask {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(22, 22, 22, 0.3);
  top: 0;
}

.home-no-stress-buttons-container {
  height: 45px;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  overflow: hidden;
}

.home-no-stress-buttons-container .ant-image img {
  width: 110px;
  cursor: pointer;
  margin: 0 15px;
  margin-top: -32px;
}

.home-no-stress-buttons-container .ant-image img:hover {
 transform: scale(1.02);
}