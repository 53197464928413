.profile-layout-header-container {
  padding: 0 !important;
  height: auto;
}

.profile-layout-content-container {
  padding: 40px 25px 90px;
  background-color: #FFFFFF;
}

.profile-layout-desktop-header-container,
.profile-layout-footer-container {
  display: none;
}

.profile-layout-toggles-container {
  margin-top: 80px;
  display: none;
}

.profile-layout-toggles-row-container {
  max-width: 750px;
  display: flex;
  justify-content: space-between;
}

@media only screen and (min-width: 768px) {
  .profile-layout-desktop-header-container {
    display: block;
    height: 250px;
    position: relative;
    background: url("../../assets/images/profile/profile-header-bg.png") no-repeat center center / cover;
  }

  .profile-layout-desktop-header-mask {
    height: 100%;
    width: 100%;
    background: linear-gradient(180deg, rgba(22, 22, 22, 0.3) 0%, rgba(22, 22, 22, 0.5) 100%);
    position: absolute;
    top: 0;
    left: 0;
  }

  .profile-layout-content-container {
    padding-left: 370px;
    padding-right: 50px;
    min-height: 700px;
  }

  .profile-layout-footer-container,
  .profile-layout-toggles-container {
    display: block;
  }

  .profile-layout-toggles-row-container {
    flex-direction: column;
  }

  .profile-layout-toggles-row-container .profile-toggle-container {
    width: 100%;
  }
}

@media only screen and (min-width: 992px) {
  .profile-layout-toggles-row-container {
    flex-direction: row;
  }

  .profile-layout-toggles-row-container .profile-toggle-container {
    width: 50%;
  }
}

@media only screen and (min-width: 1180px) {
  .profile-layout-content-container {
    padding-left: calc(50% - 230px);
  }
}