.lounge-card-container {
  margin-right: 25px;
  margin-bottom: 25px;
}

.lounge-card-container .home-service-carousel-card-container {
  min-height: 360px;
  margin: 0;
  height: auto;
}

.lounge-card-terminal-title {
  font-size: 15px;
  font-weight: 700;
  width: calc(100% - 80px);
  margin-top: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.lounge-card-services-title {
  font-size: 13px;
  font-weight: 700;
  color: var(--main-black);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 80px;
  margin-top: 10px;
}

.lounge-card-services-title:hover {
  color: var(--main-black);
}

.lounge-card-services-title .ant-image {
  width: 20px;
}

.lounge-card-services-arrow-image {
  height: 13px;
  transform: rotate(-90deg);
}

.lounge-card-services-dropdown {
  background-color: #FFFFFF !important;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 4px 30px 0px #00000014;
  padding: 9px;
  max-height: 150px;
  overflow-y: scroll;
  min-width: 110px !important;
}

.lounge-card-services-dropdown::-webkit-scrollbar {
  display: none;
}

.lounge-card-services-dropdown ul,
.lounge-card-services-dropdown ul li {
  box-shadow: none;
  padding: 0;
}

.lounge-card-service-element span {
  color: var(--main-black);
  font-size: 10px;
  height: 25px;
  border-bottom: 1px solid var(--light-border);
  display: flex;
  flex-direction: row;
}

.lounge-card-service-element span .ant-image{
  display: flex;
  align-items: center;
}

.lounge-card-service-element>span>div:nth-child(2) {
  display: flex;
  align-items: center;
  margin-left: 5px;
  width: 100%;
}

.lounge-card-labels {
  font-size: 12px;
  color: var(--secondary-gray);
  margin-top: 10px;
}

.lounge-card-info {
  font-size: 22px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
  color: var(--main-yellow);
  line-height: 16px;
}

.lounge-card-info-open {
  white-space: normal;
  overflow: auto;
}

.lounge-card-conditions-info {
  font-size: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  cursor: pointer;
  min-height: 59px;
  line-height: 16px;
  color: var(--main-yellow);
}

.lounge-card-conditions-info-open {
  display: block;
  white-space: normal;
  overflow: auto;
}

.lounge-card-conditions-info-style {
  font-size: 12px;
  color: var(--main-black);
}

.lounge-card-price {
  font-size: 20px;
  font-weight: 700;
  margin-top: 20px;
  height: 55px;
}

.lounge-card-content-col {
  width: 50%;
  margin-top: 10px;
}

.lounge-card-container .home-service-carousel-button {
  bottom: 20px;
  right: 22px;
}

@media only screen and (min-width: 1024px) {
  .lounge-card-container {
    margin-right: 35px;
  }

  .lounge-card-container .home-service-carousel-card-container {
    min-height: 440px;
    min-width: 340px;
    max-width: 340px;
  }

  .lounge-card-container .home-service-carousel-card-image {
    height: 180px;
  }

  .lounge-card-terminal-title {
    font-size: 18px;
  }

  .lounge-card-price {
    font-size: 22px;
  }

  .lounge-card-container .home-service-carousel-button {
    bottom: 24px;
    right: 26px;
  }
}