.home-service-info-container {
  width: 272px;
}

.home-service-info-title {
  font-size: 15px !important;
  color: var(--main-black) !important;
  font-weight: 500 !important;
  margin: 15px 0 10px 0 !important;
}

.home-service-info-badge {
  margin-top: 65px;
  width: 20px;
  height: 20px;
  object-fit: contain;
}

.home-service-info-description-container {
  margin-top: 20px;
  width: 270px;
}

.home-service-info-description {
  font-size: 12px;
  color: #D1D1D1;
}

.home-service-more-text u {
  cursor: pointer;
}

.home-service-info-container .home-faq-more-text {
  cursor: default;
}

@media only screen and (min-width: 768px) {
  .home-service-info-container {
    margin: 0 40px;
  }

  .home-service-info-title {
    font-size: 15px !important;
    color: #FFFFFF !important;
  }

  .home-service-more-text {
    color: #ffffff !important;
    letter-spacing: .5px;
  }
}