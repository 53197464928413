.product-header-container {
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

.product-header-container-travel-insurance {
  height: 710px;
}

.product-detail-container {
  width: 100%;
  z-index: 1;
  height: 100%;
  padding: 20px;
  line-height: 21px;
}

.product-detail-title-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1170px;
  position: relative;
}

.product-detail-title-container button {
  display: none;
}

.product-detail-title {
  font-size: 36px !important;
  font-weight: 700 !important;
  color: #ffffff !important;
  margin: 10px 0 !important;
}

.product-detail-title-line {
  width: 10px;
  height: 1px;
  background-color: var(--main-yellow);
  position: relative;
}

.product-detail-subtitle-container {
  margin: 13px 0 26px;
  position: relative;
}

.product-detail-subtitle,
.product-detail-description {
  color: #d1d1d1;
  max-width: 400px !important;
  position: relative;
}

.product-detail-description {
  min-height: 100px;
}

.product-detail-carousel-container {
  margin-top: 30px;
}

.product-detail-carousel-terms {
  position: absolute;
  bottom: 0;
  color: #d1d1d1;
  font-size: 12px;
}

.product-detail-carousel-terms span {
  color: var(--main-yellow);
  text-decoration: underline;
  cursor: pointer;
}

.product-detail-logo-container {
  width: 25px;
}

@media only screen and (min-width: 768px) {
  .product-detail-container {
    padding: 100px 80px 40px;
  }

  .product-detail-logo-container {
    position: absolute;
    top: 120px;
    left: 50px;
  }

  .product-detail-title-container button {
    display: block;
  }

  .product-header-container-travel-insurance {
    height: 740px;
  }
}

@media only screen and (min-width: 1280px) {
  .product-header-container-travel-insurance {
    height: 510px;
  }
}

@media only screen and (min-width: 1440px) {
  .product-header-container-travel-insurance {
    height: 550px;
  }

  .product-detail-container {
    padding: 120px 0px 40px;
    width: 1153px;
    margin-left: calc(50% - 577px);
  }

  .product-detail-logo-container {
    top: 140px;
    left: calc(50% - 605px);
  }

  .product-detail-carousel-container {
    margin-top: 70px;
  }
}
