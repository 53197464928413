.profile-orders-container {
  border-bottom: 3px solid var(--main-yellow);
  margin-bottom: 50px;
}

.profile-orders-date-container {
  display: flex;
  justify-content: space-between;
}

.profile-orders-date {
  font-size: 18px;
  font-weight: 700;
  letter-spacing: .5px;
  color: var(--main-black);
}

.profile-orders-receipt-download {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: var(--main-yellow);
  cursor: pointer;
}

.profile-orders-receipt-download:hover {
  color: var(--dark-yellow);
}

.profile-orders-receipt-number-text {
  font-size: 12px;
  color: var(--secondary-gray);
}

.profile-orders-labels-container {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.profile-orders-labels-container span {
  font-size: 13px;
  font-weight: 500;
  color: var(--secondary-gray);
  width: 50%;
}

.profile-orders-labels-container span:nth-child(2) {
  display: flex;
  justify-content: center;
  width: 10%;
  white-space: nowrap;
}

.profile-orders-labels-container span:nth-child(3) {
  text-align: end;
  width: 33%;
}

.dotted-line {
  display: flex;
  border: 1px solid var(--secondary-gray);
  border-style: none none dashed;
  margin: 10px 0 20px;
}

.profile-orders-deatils-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.profile-orders-deatils-container .ant-image {
  margin-right: 5px;
  width: 20px;
  display: flex;
}

.profile-orders-deatils-container span {
  font-size: 15px;
  font-weight: 500;
  width: 50%;
  display: flex;
  margin-bottom: 5px;
}

.profile-orders-deatils-container span:nth-child(2) {
  justify-content: center;
  width: 10%;
}

.profile-orders-deatils-container span:nth-child(3) {
  justify-content: flex-end;
  width: 33%;
}

.profile-orders-total-cost-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.profile-orders-total-cost-container>span:nth-child(1) {
  font-size: 15px;
  font-weight: 500;
  color: var(--main-black);
}

.profile-orders-total-cost-text,
.profile-orders-total-cost-text span {
  font-size: 18px;
  font-weight: 700 !important;
  letter-spacing: .5px;
}

.profile-orders-no-items-image-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-orders-no-items-image-container .ant-image {
  width: 60%;
  max-width: 180px;
  margin: 30px 0;
}

.profile-orders-order-amount span {
  width: auto !important;
}

.profile-orders-no-items-image-container .ant-typography {
  font-weight: 700;
  font-size: 22px;
  text-align: center;
}

.profile-loader-container {
  height: 100px;
}

.profile-orders-b2b-receipt-info {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.profile-orders-b2b-receipt-info>.ant-col {
  display: flex;
  flex-direction: column;
}

.profile-orders-b2b-receipt-info>.ant-col>span:nth-child(1) {
  font-size: 12px;
  color: var(--secondary-gray);
}

.profile-orders-b2b-receipt-info>.ant-col>span:nth-child(2) {
  font-size: 13px;
}

.profile-orders-initial-cost-text,
.profile-orders-initial-cost-text span {
  font-size: 15px !important;
  margin-right: 5px;
  text-decoration: line-through;
  text-decoration-color: red;
}

.profile-orders-deatils-container .ant-image {
  cursor: pointer;
}

@media only screen and (min-width: 768px) {

  .profile-orders-date {
    font-size: 22px;
  }

  .profile-orders-labels-container span:nth-child(3),
  .profile-orders-deatils-container span:nth-child(3) {
    max-width: 90px;
  }

  .profile-orders-labels-container span:nth-child(2),
  .profile-orders-deatils-container span:nth-child(2) {
    padding-left: 10%;
  }
  
  .profile-orders-deatils-container .ant-image {
    margin-right: 18px;
    width: 30px;
  }
}

@media only screen and (min-width: 992px) {

  .profile-orders-labels-container span,
  .profile-orders-deatils-container span {
    width: 33%;
  }
}