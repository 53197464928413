.search-flights-list-screen-container {
  padding-bottom: 110px;
}

.search-flights-list-header {
  width: 100%;
  height: 214px;
  background-color: var(--main-black);
  display: flex;
  flex-direction: column;
}

.search-flights-list-header .ant-image {
  margin: 0 auto !important;
}

.search-flights-list-header-text-container {
  display: flex;
  justify-content: space-between;
  width: 260px;
  max-width: 100%;
  margin: 8px auto;
}

.search-flights-list-header-text {
  color: #FFFFFF;
  width: 50px;
  text-align: center;
}

.flight-no-results {
  width: 70%;
  margin-left: 15%;
  margin-top: 40px;
  text-align: center;
}

@media only screen and (min-width: 768px) {
  .search-flights-list-header {
    padding-top: 120px;
  }
}