.home-service-carousel-card-title {
  font-size: 15px !important;
  color: var(--main-black) !important;
  margin: 20px 0 0px 0 !important;
  font-weight: bold !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.home-service-carousel-card-subtitle {
  font-size: 11px;
  color: var(--secondary-gray);
  line-height: 35px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.home-service-carousel-card-description {
  font-size: 11px;
  color: var(--main-black);
}

.home-service-carousel-card-description-container {
  height: 47px;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.home-service-carousel-card-description-container::-webkit-scrollbar {
  display: none;
}