.profile-account-container {
  max-width: 750px;
  position: relative;
}

.profile-account-title-desktop {
  display: none;
}

.profile-account-title-desktop h1 {
  font-size: 26px !important;
  font-weight: 700 !important;
  color: var(--main-black);
}

.profile-account-title-desktop-line {
  width: 35px;
  height: 3px;
  background-color: var(--main-yellow);
  margin-bottom: 50px;
}

.profile-account-input-country-container,
.profile-account-input-gender-container {
  height: 105px;
  padding-right: 4px;
}

.profile-account-input-country-label {
  color: var(--secondary-gray);
  font-size: 12px;
  font-weight: 500;
}

.profile-account-input-gender-container .ant-select .ant-select-selector {
  margin-top: 13px;
  height: 48px;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 700;
  padding-top: 8px;
  border-color: var(--light-border);
}

.profile-account-input-gender-container .ant-select:hover .ant-select-selector {
  border: 1px solid var(--light-border);
}

.profile-account-input-gender-container .ant-select-focused .ant-select-selector {
  border: 1px solid var(--light-border) !important;
}

.profile-account-input-gender-container .ant-select-disabled .ant-select-selector {
  background-color: transparent !important;
  border: none !important;
  padding-left: 0;
  color: var(--main-black) !important;
}

.profile-account-input-gender-container .ant-select-disabled .ant-select-arrow {
  display: none;
}

.profile-account-input-gender-container .ant-select .ant-select-arrow {
  padding-top: 8px;
}

.profile-account-input-country-container .login-input-country-select,
.profile-account-input-country-container .login-input-country-select-disabled {
  margin-top: 13px;
}

.profile-account-input-country-container .login-input-country-select button {
  border: 1px solid var(--light-border);
  height: 48px;
  border-radius: 5px;
}

.profile-account-input-country-container .login-input-country-select button span,
.profile-account-input-country-container .login-input-country-select-disabled button span {
  font-weight: 700 !important;
  font-size: 18px;
}

.profile-account-logout-button-h-line {
  width: 100%;
  height: 1px;
  background-color: #D1D1D1;
  margin: 40px 0 25px;
}

.profile-account-input-date-picker {
  border: 1px solid var(--light-border);
  height: 48px;
  border-radius: 5px;
  margin-top: 13px;
  font-size: 18px;
  font-weight: 700 !important;
}

.profile-account-input-date-picker:hover {
  border: 1px solid var(--light-border) !important;
}

.ant-picker-disabled:hover {
  border: none !important;
}

.profile-account-input-date-picker .ant-picker-input input {
  font-size: 18px;
  font-weight: 700 !important;
  color: var(--main-black);
}

.profile-account-input-date-picker div input::placeholder {
  color: var(--main-black);
}

.profile-account-input-country-container .ant-picker-disabled {
  background-color: transparent;
  padding-left: 0;
  border: none;
}

.profile-account-input-country-container .ant-picker-disabled span {
  display: none;
}

.profile-account-loader-container {
  height: 100px;
}

.profile-account-input-button-responsive .button-primary-style-xs:hover {
  background-color: var(--dark-yellow);
}

.profile-account-input-button-responsive .button-primary-style-xs-confirm:hover {
  background-color: #60dd7f !important;
  border: 1px solid #60dd7f !important;
}

@media only screen and (min-width: 768px) {
  .profile-account-title-desktop {
    display: flex;
    flex-direction: column;
  }

  .profile-account-input-button-responsive {
    position: absolute;
    top: 0;
    right: 0;
  }

  .profile-account-logout-button-container {
    display: none;
  }
}

@media only screen and (min-width: 992px) {
  .profile-account-input-row-responsive {
    display: flex;
  }
}