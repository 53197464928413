.home-header-container {
  width: 100%;
  height: 110px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  position: relative;
  z-index: 10;
  transition: all .1s ease-in-out;
}

.product-detail-header .home-header-container {
  display: none;
}

.login-header-logo-container {
  height: 100%;
  display: flex;
  align-items: center;
}

.login-header-logo {
  height: 46px;
  width: 117px;
}

.home-header-buttons-container {
  height: 100%;
  display: none;
  align-items: center;
  justify-content: flex-end;
}

.home-header-area-business-link {
  font-size: 12px;
  color: #FFFFFF;
  cursor: pointer;
  height: 30px;
  line-height: 30px;
  margin: 0 10px;
}

.home-header-area-business-link:hover {
  color: var(--main-yellow);
}

.home-header-button-lang {
  display: flex;
  margin-left: 10px;
}

.hidden-logo-mobile {
  display: none;
}

@media only screen and (min-width: 768px) {
  .home-header-buttons-container {
    display: flex;
  }

  .hidden-logo-mobile {
    display: block;
    display: flex;
  }

  .home-header-container {
    position: fixed;
    top: 0;
  }

  .home-header-sticky {
    background-color: #FFFFFF;
    box-shadow: 0px 1px 8px 0px #0000001A;
  }
  
  .home-header-sticky-no-shadow {
    background-color: #FFFFFF;
  }

  .product-detail-header .home-header-container {
    display: flex;
  }
}