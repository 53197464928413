.mobile-navbar-container {
  width: 100%;
  height: 72px;
  background-color: #FFFFFF;
  display: flex;
  justify-content: space-around;
  padding: 0 15px;
  position: fixed;
  bottom: -1px;
  z-index: 100;
  border-top: 1px solid #D1D1D1;
}

.mobile-navbar-button-container {
  height: 100%;
  width: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.mobile-navbar-button-link {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px 3px 0 0;
  position: relative;
  z-index: 1;
  cursor: pointer;
}

.mobile-navbar-button-mask {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #EAEAEA;
  opacity: 0.5 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
}

.mobile-navbar-cart-number {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: var(--main-yellow);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 9px;
  padding-top: 2px;
  color: #FFFFFF;
  position: absolute;
  top: 0px;
  right: 0px;
}

@media only screen and (min-width: 768px) {
  .mobile-navbar-container {
    display: none;
  }
}